import React, {useEffect, useState} from "react"
import axios from "axios";
import {ArsDocumentsType} from "../../../Shared/Enums/ArsDocumentsType";
import {toast} from "react-toastify";
import {generatePdfIframe} from "../../../Shared/PDF/PDFManager";
import Loader from "../../Controle/Loader";
import Helper from "../../../Shared/Helper";


const sortAndFormatDocuments = (document_list) => {
    let documents = document_list.map((document) => {
        document.iframe = null
        return document
    })

    documents.sort((document_a, document_b) => {
        let a_full_signed = document_a.user_signatures.every((signature) => signature.date !== null)
        let b_full_signed = document_b.user_signatures.every((signature) => signature.date !== null)

        if (a_full_signed === b_full_signed) return 0
        if (a_full_signed && !b_full_signed) return 1
        return -1
    })

    return documents
}

export default function OrthoptistPanel(props) {

    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content

    const [documents, setDocuments] = useState(sortAndFormatDocuments(props.data.documents.documents))

    useEffect(() => {
        let elements = document.getElementById("arsDocumentDropdown")
        let instances = M.Collapsible.init(elements, {});
        return () => {
            instances.destroy()
        }
    }, []);

    const signDocument = (document_id) => {
        axios.patch(`/signDocument/${document_id}`)
            .then(() => {
                let modified_document
                let temp_documents = documents.map((document) => {
                    if (document.id === document_id) {
                        let signature_index = document.user_signatures.findIndex(
                            (signature) => signature.user_id === props.data.user_id)
                        document.user_signatures[signature_index].date = new Date()
                        modified_document = document
                    }
                    return document
                })
                handlePdf(modified_document, true)
                setDocuments(temp_documents)
                toast.success("Votre document est désormais signé.")
            })
            .catch((error) => toast.error("Erreur lors de la signature du document."))
    }


    const get_missing_elements = (document_type) => {
        let missing_infos = []
        switch (document_type) {
            case ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.document_type:
                if (!props.data.last_name) missing_infos.push("Votre nom de famille")
                if (!props.data.first_name) missing_infos.push("Votre prénom")
                if (!props.data.documents.ophtalmologist.city) missing_infos.push("La ville du cabinet.")
                if (!props.data.documents.ophtalmologist.last_name?.length) missing_infos.push("Le nom de famille de l'ophtalmologiste")
                if (!props.data.documents.ophtalmologist.first_name?.length) missing_infos.push("Le prénom de l'ophtalmologiste")
                if (!props.data.documents.ophtalmologist.city) missing_infos.push("L'adresse du cabinet ophtalmologiste")
                break
            case ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.document_type:
                if (!props.data.last_name) missing_infos.push("Votre nom de famille")
                if (!props.data.first_name) missing_infos.push("Votre prénom")
                if (!props.data.documents.ophtalmologist.last_name?.length) missing_infos.push("Le nom de famille de l'ophtalmologiste")
                if (!props.data.documents.ophtalmologist.first_name?.length) missing_infos.push("Le prénom de l'ophtalmologiste")
                if (!props.data.documents.ophtalmologist.city) missing_infos.push("La ville du cabinet ophtalmologiste")
                if (!props.data.rpps) missing_infos.push("Votre numéro RPPS")
                if (!props.data.adeli) missing_infos.push("Votre numéro Adeli")
                break
            case ArsDocumentsType.EMPLOYER_ACCORD_6_15.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_16_50.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_RETINO.document_type:
                if (!props.data.last_name) missing_infos.push("Votre nom de famille")
                if (!props.data.first_name) missing_infos.push("Votre prénom")
                if (!props.data.office.director_fullname) missing_infos.push("Le nom du directeur de cabinet")
                if (!props.data.documents.ophtalmologist.last_name?.length) missing_infos.push("Le nom de famille de l'ophtalmologiste")
                if (!props.data.documents.ophtalmologist.first_name?.length) missing_infos.push("Le prénom de l'ophtalmologiste")
                if (!props.data.office.name) missing_infos.push("Le titre du cabinet")
            case ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.document_type:
                if (!props.data.last_name) missing_infos.push("Votre nom de famille")
                if (!props.data.first_name) missing_infos.push("Votre prénom")
                if (!props.data.documents.ophtalmologist.last_name?.length) missing_infos.push("Le nom de famille de l'ophtalmologiste")
                if (!props.data.documents.ophtalmologist.first_name?.length) missing_infos.push("Le prénom de l'ophtalmologiste")
                if (!props.data.office.city) missing_infos.push("La ville du cabinet")
                if (!props.data.documents.ophtalmologist.city) missing_infos.push("La ville de l'ophtalmologiste")

            default:
                break
        }
        return missing_infos
    }

    const user_missing_infos_cards = () => {
        let missing_data = false
        if (!props.data.last_name) missing_data = true
        if (!props.data.first_name) missing_data = true
        if (missing_data) return <div className="card-panel red lighten-3">
            Des informations importantes sont manquantes pour la finition des dossiers ARS:
            {!props.data.last_name?.length ? <div style={{paddingTop: 5}}> - Votre nom de famille.<br/></div> : null}
            {!props.data.first_name?.length ? <div> - Votre prénom.</div> : null}
            <div style={{display: "flex", justifyContent: "center"}}>
                <a className="btn red lighten-1 z-depth-0 center"
                   href="/orthoptistes/administration#/personal_infos">
                    Remplir mes informations.</a>
            </div>
        </div>
        else return null
    }

    const isDocumentSigned = (document) => {

        let signature_ophtalmologist = getOphtalmologistSignature(document)
        let signature_orthoptist = getOrthoptistSignature(document)

        if ((signature_ophtalmologist && signature_ophtalmologist.date) && (signature_orthoptist && signature_orthoptist.date)) {
            return <i className="material-icons green-text">check_circle</i>
        }
        if (signature_orthoptist && signature_orthoptist.date) {
            return <i className="material-icons">hourglass_empty</i>
        }
        return <i className="material-icons red-text">cancel</i>

    }

    const renderSignatureButton = (document) => {
        let signature_orthoptist = getOrthoptistSignature(document)

        if (signature_orthoptist && signature_orthoptist.date !== null) {
            return <a className="btn blue darken-2 z-depth-0 disabled center"
                      style={{display: "flex", alignItems: "center", width: "fit-content", alignSelf: "center"}}>
                <i className="material-icons-outlined" style={{marginRight: 5}}>check_circle</i>
                <div>Déjà signé</div>
            </a>
        }

        let missing_data = get_missing_elements(document.document_type)
        if (missing_data.length) {
            return <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: 10,
                marginLeft: 20
            }}>
                Des informations sont manquantes avant de permettre la signature:
                {missing_data.map((element) => <div>- {element}<br/></div>)}
            </div>
        }

        return <a className="btn blue darken-2 z-depth-0"
                  onClick={() => signDocument(document.id)}
                  style={{display: "flex", alignItems: "center", width: "fit-content", alignSelf: "center"}}>
            <i className="material-icons" style={{marginRight: 5}}>edit_note</i>
            <div>Signer le document</div>
        </a>
    }

    const renderOrthoptistSignatureInfos = (document) => {
        let signature_orthoptist = getOrthoptistSignature(document)

        if (signature_orthoptist && signature_orthoptist.date !== null) {
            return <div style={{display: "flex", alignItems: "center"}}>
                <i className="material-icons-outlined green-text" style={{marginRight: 10}}>check_circle</i>
                <div>
                    Votre signature:<br/>
                    Signé le {Helper.formatDate(signature_orthoptist.date)}
                </div>
            </div>
        }
        return <div style={{display: "flex", alignItems: "center"}}>
            <i className="material-icons-outlined red-text" style={{marginRight: 10}}>cancel</i>
            <div>
                Votre signature:<br/>
                En attente de votre signature.
            </div>
        </div>
    }

    const renderOphtalmologistSignatureInfos = (document) => {
        let signature_ophtalmologist = getOphtalmologistSignature(document)
        let signature_orthoptist = getOrthoptistSignature(document)

        if (signature_ophtalmologist && signature_ophtalmologist.date !== null) {
            return <div style={{display: "flex", alignItems: "center"}}>
                <i className="material-icons-outlined green-text" style={{marginRight: 10}}>check_circle</i>
                <div>
                    Signature de l'ophtalmologiste:<br/>
                    Signé le {Helper.formatDate(signature_ophtalmologist.date)}
                </div>
            </div>
        }
        if ((signature_ophtalmologist && signature_ophtalmologist.date === null) && (signature_orthoptist && signature_orthoptist.date !== null)) {
            return <div style={{display: "flex", alignItems: "center"}}>
                <i className="material-icons-outlined red-text" style={{marginRight: 10}}>cancel</i>
                <div>
                    Signature de l'ophtalmologiste:<br/>
                    En attente de la signature.
                </div>
            </div>
        }

        return <div style={{display: "flex", alignItems: "center"}}>
            <i className="material-icons-outlined" style={{marginRight: 10}}>hourglass_empty</i>
            <div>
                Signature de l'ophtalmologiste:<br/>
                En attente de la signature.
            </div>
        </div>
    }

    const getOphtalmologistSignature = (document) => {
        const ophtalmologist_user_id = props.data.documents.ophtalmologist.user_id
        return document.user_signatures.find((signature) => signature.user_id === ophtalmologist_user_id)
    }

    const getOrthoptistSignature = (document) => {
        return document.user_signatures.find((signature) => signature.user_id === props.data.user_id)
    }

    const handlePdf = async (modified_document, ignore_existing_iframe = false) => {

        if (modified_document.iframe && !ignore_existing_iframe) return

        let signature_count = modified_document.user_signatures.map((signature) => signature.date !== null).filter(Boolean).length

        let signature_date
        if (signature_count === modified_document.user_signatures.length) {
            signature_date = modified_document.user_signatures.reduce((a, b) => {
                return new Date(a.date) > new Date(b.date) ? a : b;
            })
            signature_date = signature_date.date
        }
        if (modified_document.user_signatures.length - signature_count === 1) signature_date = new Date()

        let signature_ophtalmologist = Helper.toCamelCase(`${props.data.documents.ophtalmologist.first_name} ${props.data.documents.ophtalmologist.last_name}`)
        let signature_orthoptist = Helper.toCamelCase(`${props.data.first_name} ${props.data.last_name}`)

        let pdf_data = {}
        let document_uri
        let document_title
        switch (modified_document.document_type) {
            case ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.document_type:
                document_uri = ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.url
                document_title = ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.title
                break
            case ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.document_type:
                document_uri = ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.url
                document_title = ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.title
                break
            case ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.document_type:
                document_uri = ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.url
                document_title = ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.title
                break
            case ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.document_type:
                document_uri = ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.url
                document_title = ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.title
                break
            case ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.document_type:
                document_uri = ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.url
                document_title = ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.title
                break
            case ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.document_type:
                document_uri = ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.url
                document_title = ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.title
                break
            case ArsDocumentsType.EMPLOYER_ACCORD_6_15.document_type:
                document_uri = ArsDocumentsType.EMPLOYER_ACCORD_6_15.url
                document_title = ArsDocumentsType.EMPLOYER_ACCORD_6_15.title
                break
            case ArsDocumentsType.EMPLOYER_ACCORD_16_50.document_type:
                document_uri = ArsDocumentsType.EMPLOYER_ACCORD_16_50.url
                document_title = ArsDocumentsType.EMPLOYER_ACCORD_16_50.title
                break
            case ArsDocumentsType.EMPLOYER_ACCORD_RETINO.document_type:
                document_uri = ArsDocumentsType.EMPLOYER_ACCORD_RETINO.url
                document_title = ArsDocumentsType.EMPLOYER_ACCORD_RETINO.title
                break
            case ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.document_type:
                document_uri = ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.url
                document_title = ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.title
                break
            default:
                break
        }

        switch (modified_document.document_type) {
            case ArsDocumentsType.EMPLOYER_ACCORD_6_15.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_16_50.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_RETINO.document_type:
                pdf_data = {
                    nom_employeur: Helper.toCamelCase(props.data.office.director_fullname) ?? "",
                    nom_ophtalmologiste1: Helper.formatName(props.data.documents.ophtalmologist.first_name, props.data.documents.ophtalmologist.last_name) ?? "",
                    ville_employeur: Helper.toCamelCase(props.data.office.city) ?? "",
                    nom_structure: Helper.toCamelCase(props.data.office.name) ?? "",
                    nom_orthoptiste1: Helper.formatName(props.data.first_name, props.data.last_name) ?? "",
                    date: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_employeur: Helper.toCamelCase(props.data.office.director_fullname)
                }
                break

            case ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.document_type:
                pdf_data = {
                    nom_ophtalmo1: Helper.capitalizeFirstLetter(props.data.documents.ophtalmologist.last_name) ?? "",
                    prenom_ophtalmo1: Helper.capitalizeFirstLetter(props.data.documents.ophtalmologist.first_name) ?? "",
                    profession_delegant: "Ophtalmologiste",
                    profession_delegue: "Orthoptiste",
                    ville_ophtalmo: props.data.documents.ophtalmologist.city ?? "",
                    rpps_ophtalmo1: props.data.documents.ophtalmologist.rpps ?? "",
                    adeli_ortho1: props.data.adeli ?? "",
                    prenom_ortho1: Helper.capitalizeFirstLetter(props.data.first_name) ?? "",
                    nom_ortho1: Helper.capitalizeFirstLetter(props.data.last_name) ?? "",
                    date: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ophtalmo1: signature_ophtalmologist,
                    signature_ortho1: signature_orthoptist
                }
                break

            case ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.document_type:
                pdf_data = {
                    nom_ophtalmologiste1: Helper.formatName(props.data.documents.ophtalmologist.first_name, props.data.documents.ophtalmologist.last_name) ?? "",
                    nom_ophtalmologiste1b: Helper.formatName(props.data.documents.ophtalmologist.first_name, props.data.documents.ophtalmologist.last_name) ?? "",
                    nom_orthoptiste1: Helper.formatName(props.data.first_name, props.data.last_name) ?? "",
                    ville_ophtalmo: props.data.documents.ophtalmologist.city ?? "",
                    date: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ophtalmo1: signature_ophtalmologist
                }
                break
            case ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.document_type:
                pdf_data = {
                    nom_ophtalmologiste1: Helper.formatName(props.data.documents.ophtalmologist.first_name, props.data.documents.ophtalmologist.last_name) ?? "",
                    ville_ophta: props.data.documents.ophtalmologist.city ?? "",
                    date_ophta:  signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ophta: signature_ophtalmologist,
                    nom_orthoptiste1: Helper.formatName(props.data.first_name, props.data.last_name) ?? "",
                    nom_ophtalmologiste1b: Helper.formatName(props.data.documents.ophtalmologist.first_name, props.data.documents.ophtalmologist.last_name) ?? "",
                    ville_ortho: props.data.office.city ?? "",
                    date_ortho: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ortho: signature_orthoptist
                }
                break
            default:
                break
        }

        await generatePdfIframe(document_uri, document_title, pdf_data)
            .then((iframe_doc) => {
                    let modified_array = documents.map((document) => {
                        if (document.id === modified_document.id) {
                            return {...document, iframe: iframe_doc}
                        }
                        return document
                    })
                    setDocuments(modified_array)
                }
            )
            .catch((error) => console.log(error))
    }

    const renderIframe = (iframe) => {
        if (iframe) {
            return <div style={{height: "500px", marginTop: 20, marginBottom: 20}}>
                {iframe}
            </div>
        }
        return <div className="center"><Loader wide text="Chargement du document..."/></div>
    }

    return <div className="container"
                style={{width: "80%", padding: 20, height: "100%", display: "flex", flexDirection: "column", gap: 10}}>
        <h1 className="center" style={{margin: "20px 0 0 0"}}>Mes documents ARS</h1>

        <h3 className="center"
            style={{marginBottom: 5}}>{Helper.capitalizeFirstLetter(props.data.office.name)}</h3>
        {user_missing_infos_cards()}
        <ul className="collapsible" id="arsDocumentDropdown">
            {documents.map((document) => {
                return (
                    <li key={document.id}>
                        <div className="collapsible-header"
                             onClick={() => handlePdf(document)}>
                            {isDocumentSigned(document)}
                            {ArsDocumentsType[document.document_type].title}
                        </div>
                        <div className="collapsible-body">
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <div style={{display: "flex", justifyContent: "space-around"}}>
                                    {renderOrthoptistSignatureInfos(document)}
                                    {renderOphtalmologistSignatureInfos(document)}
                                </div>
                                {renderIframe(document.iframe)}
                                {renderSignatureButton(document)}
                            </div>
                        </div>
                    </li>
                )
            })}
        </ul>

    </div>
}