import React from 'react';
import {Mesures, TypesMesures} from "../../../../Shared/Enums/Enums";
import MesureOverview from "../MesureOverview";

const Measures = ({appointment}) => {
    return <div>
        <h6 style={{fontWeight: "bolder"}}>Mesures :</h6>
        <table>
            <tbody>
            {appointment.mesures.map(mesure => {
                let type = ""
                if (mesure.typedemesure.titre === TypesMesures.TENSION) type = Mesures.TENSION.value
                if (mesure.typedemesure.titre === TypesMesures.PACHYMETRY) type = Mesures.PACHYMETRY.value
                return <tr>
                    <td style={{fontSize: 12, padding: 0}}>{mesure.typedemesure.titre}</td>
                    <td style={{fontSize: 12, padding: 5}}><MesureOverview mesure={mesure} type={{name: type}}/>
                    </td>
                </tr>

            })}
            </tbody>
        </table>
    </div>
}

export default Measures;