import React, {useState} from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import ReportDocument from "./ReportDocument";

const pageStyle = `
@media print {
   body {
       display: block;
       box-sizing: border-box;   
       padding: 20mm 15mm;
       height: auto;
   }
   @page {
      size: auto;   /* auto is the initial value */
      margin: 0;  /* this affects the margin in the printer settings */
   }
}
`;

function ReportPrintButton(props) {
    let toPrint = null
    const [templateData, setTemplateData] = useState({});

    const getTemplate = () => {
        axios.get("/orthoptistes/reports/selected_template", {params: {appointment_id: props.appointment_id}})
            .then((resp) => {
                setTemplateData(resp.data)
                document.getElementById(`rtp-temp-${props.appointment_id}`).click()
            })
    }

    const display = (templateData) => {
        if (templateData === undefined) return <div ref={el => (toPrint = el)}/>
        const template = {blocks: templateData.template}
        const hiddenBlocks = templateData.hiddenBlocks
        const values = templateData.values
        if (template && template.blocks) {
            return <div ref={el => (toPrint = el)}>
                <ReportDocument template={template} values={values} hiddenBlocks={hiddenBlocks}/>
            </div>
        } else return <div ref={el => (toPrint = el)}/>;
    }

    return (
        <div>
            <a className={`btn btn-small blue darken-2 z-depth-0 ${props.disabled ? "disabled" : ""}`}  onClick={getTemplate}>Imprimer le rapport</a>
            <div className="hide">
                <ReactToPrint
                    pageStyle={pageStyle}
                    trigger={() => (
                        <a className="btn blue darken-2 z-depth-0" id={`rtp-temp-${props.appointment_id}`}/>
                    )}
                    content={() => toPrint}
                />
                {display(templateData)}
            </div>
        </div>
    );
}

export default ReportPrintButton;

ReportPrintButton.defaultProps = {
    appointment_id: undefined,
    disabled: false
}