import React from "react";

const DocumentReview = ({patient, appointment}) => {
    return <>
        {/*Antécédents Médicaux*/}
        <h5 style={{marginTop: 5}}><b>Antécédents : </b>{patient.history ? patient.history : " RAS"}</h5>
        {/*Anamnèse*/}
        <h5 style={{marginBlock: 0}}><b>Anamnèse : </b>{appointment.anamnese ? appointment.anamnese : " RAS"}</h5>
        {/*Texte Orthoptique*/}
        <h5 style={{ marginTop: 20}}><b>Orthoptie : </b>{appointment.orthoptic_text ? appointment.orthoptic_text : " RAS"}</h5>
    </>
}

export default DocumentReview;