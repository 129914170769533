import React from "react";
import {toast} from "react-toastify";
import axios from "axios";
import {t} from "../../../I18n/i18n";


export default function OfficePanel({office = {}, url_prefix}) {
    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

    const save = (e) => {
        e.preventDefault()
        const name = e.currentTarget.office_name.value
        const address = e.currentTarget.office_address.value
        const city = e.currentTarget.office_city.value
        const postal_code = e.currentTarget.office_postal_code.value
        const director_fullname = e.currentTarget.office_director_name.value

        axios.post(`${url_prefix}/administration/update_office`, {
            name: name,
            address: address,
            city: city,
            postal_code: postal_code,
            director_fullname: director_fullname
        }).then((response) => {
            toast.success(t("OPTIONS.OFFICE.SUCCESS"))
        }).catch((error) => {
            toast.error(t("OPTIONS.OFFICE.ERROR"))
        })
    }

    return <div className="container" style={{width: "100%", padding: 20, height: "100%"}}>
        <h1 className="center">{t("OPTIONS.OFFICE.TITLE")}</h1>
        {/* Name, Address, City, Postal code, director's name*/}
        <form style={{display: "flex", flexDirection: "column", gap: 20}} onSubmit={save}>
            <div className="input-field outlined">
                <input id="office_name" type="text" className="validate" defaultValue={office.name}/>
                <label htmlFor="office_name">{t("NAME")}</label>
            </div>
            <div className="input-field outlined">
                <input id="office_address" type="text" className="validate" defaultValue={office.address}/>
                <label htmlFor="office_address">{t("ADDRESS")}</label>
            </div>
            <div className="input-field outlined">
                <input id="office_city" type="text" className="validate" defaultValue={office.city}/>
                <label htmlFor="office_city">{t("CITY")}</label>
            </div>
            <div className="input-field outlined">
                <input id="office_postal_code" type="text" className="validate" defaultValue={office.postal_code}/>
                <label htmlFor="office_postal_code">{t("POSTAL_CODE")}</label>
            </div>
            <div className="input-field outlined">
                <input id="office_director_name" type="text" className="validate"
                       defaultValue={office.director_fullname}/>
                <label htmlFor="office_director_name">{t("OPTIONS.OFFICE.DIRECTOR_NAME")}</label>
            </div>
            <div>
                <button className="btn blue darken-2 z-depth-0" type="submit" name="action">{t("SAVE")}</button>
            </div>
        </form>


    </div>

}