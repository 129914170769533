import React, {useEffect, useState} from "react"
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import HelpButton from "../../../Shared/HelpButton";
import {getEnv} from "../../../Shared/Helper";
import axios from "axios";
import Pincode from "../../../Shared/Pincode/Pincode";
import {toast} from "react-toastify"
import Toastr from "../../../Shared/Toastr"
import {wrapper} from "../../../I18n/i18n";

const TWO_FA_STATUS = {
    DEACTIVATED: "deactivated",
    BACK_UP_CODES: "back_up_codes",
    ALL_SETUP: "all_setup"
}
export default function TwoFactorAuthPanel(props) {
    const get_2fa_status = () => {
        if (!props.activated) return TWO_FA_STATUS.DEACTIVATED
        if (!props.code_generated) return TWO_FA_STATUS.BACK_UP_CODES
        return TWO_FA_STATUS.ALL_SETUP
    }

    const [two_fa_status, setTwoFaStatus] = useState(get_2fa_status())

    const HELP_BUTTON_TEXT = `"Sur votre téléphone, ouvrez votre application de gestion de mots de passe à usage unique
        (comme Authy ou Google Authenticator). Vous allez pouvoir scanner un QR code pour faire le lien entre 
        ${getEnv("BRAND") === "WHITE" ? "ExpertVision" : "Temeoo"} et ce dernier.
        Une fois le code obtenu vous n'avez plus qu'à le renseigner sur cette page et à confirmer votre mot de passe.
        Validez avec le bouton et le tour est joué !"`

    const render2FAcomponent = () => {
        if (two_fa_status === TWO_FA_STATUS.DEACTIVATED) return <Setup2FA url_prefix={props.url_prefix}
                                                                          setTwoFaStatus={setTwoFaStatus}/>
        if (two_fa_status === TWO_FA_STATUS.BACK_UP_CODES) return <Existing2FA url_prefix={props.url_prefix}/>
        else return <Deactivate2FA setTwoFaStatus={setTwoFaStatus} url_prefix={props.url_prefix}/>
    }

    return (
        <div className="container" style={{width: "80%"}}>
            <div className="valign-wrapper" style={{display: "flex", justifyContent: "center"}}>
                <h1>Double authentification</h1>
                <HelpButton
                    text={HELP_BUTTON_TEXT}
                    videoSrc="/videos/register_2FA.mp4"
                    height="50vh"
                    size="small"
                />
            </div>
            {render2FAcomponent()}
            <Toastr/>
        </div>
    )
}

function Setup2FA(props) {
    const [request_state, setRequestState] = useState(RequestState.LOADING)
    const [pin_value, setPinValue] = useState("")
    const [password, setPassword] = useState("")
    const [show_password, setShowPassword] = useState(false)
    const [qr_code, setQrCode] = useState(null)
    const [code_string, setCodeString] = useState("")

    useEffect(() => {
        axios.get(`${props.url_prefix}/two_factor_settings/initial_codes`)
            .then((response) => {
                setQrCode(response.data.qr_code)
                setCodeString(response.data.code_string)
                setRequestState(RequestState.SUCCESS)
            })
            .catch((_) => setRequestState(RequestState.ERROR))
    }, []);

    const activate_2fa = () => {
        let data = {password: password, code: pin_value}
        axios.post(`${props.url_prefix}/two_factor_settings/`, data)
            .then((_) => {
                toast.success("2FA activée avec succès.", {autoClose: 1500})
                setTimeout(() => props.setTwoFaStatus(TWO_FA_STATUS.BACK_UP_CODES), 1500)
            })
            .catch((_) => toast.error("Erreur lors de l'activation de la 2FA."))
    }

    const are_valid_values = () => {
        if (pin_value.length !== 6 || !/^\d+$/.test(pin_value)) return false
        if (!password) return false
        return true
    }

    if (request_state === RequestState.LOADING) return <div className="center"><Loader text="Chargement en cours..."/>
    </div>
    if (request_state === RequestState.ERROR) return <div className="center">Une erreur est survenue.</div>
    if (request_state === RequestState.SUCCESS) return (
        <div className="row" data-cy="2FASetup">
            <div className="col s12 m6">
                <p>
                    Scannez le QR code à l'aide d'une application de génération de mot de passes temporaires (comme
                    Google Authenticator ou Authy)
                </p>
                <div className="center" data-cy="QRCode" dangerouslySetInnerHTML={{__html: qr_code}}/>
            </div>
            <div className="col s12 m6">
                <p> Si vous n'êtes pas en mesure de scanner le code si dessous, vous pouvez utiliser ce code à la
                    place:<br/>
                    <code data-cy="WrittenCode">{code_string}</code></p>
                <p>Confirmez le code que vous obtenez (attention il change toutes les 30 secondes) et n'oubliez pas
                    de rentrer votre mot de passe avant de valider.
                </p>

                <Pincode onChange={setPinValue}/>
                <br/>

                <div className="input-field outlined ">
                    <input id="2fa_password"
                           data-cy="InputPassword"
                           type={show_password ? "text" : "password"}
                           onChange={(e) => setPassword(e.target.value)}/>
                    <label htmlFor="2fa_password">Entrez votre mot de passe actuel</label>
                    <div id="hoverable">
                        <i className="material-icons"
                           data-cy="ShowPasswordButton"
                           onClick={() => setShowPassword(!show_password)}
                           style={{position: "absolute", right: "5%", top: "30%"}}
                        >{show_password ? "visibility" : "visibility_off"}</i>
                    </div>
                </div>

                <br/>
                <a className={`btn blue darken-2 text-white z-depth-0 center ${are_valid_values() ? "" : "disabled"}`}
                   onClick={activate_2fa}>
                    Activer la double authentification
                </a>
            </div>
        </div>
    )
}

function Existing2FA(props) {

    const [back_up_codes, setBackUpCodes] = useState(null)
    const [request_state, setRequestState] = useState(RequestState.LOADING)

    useEffect(() => {
        getBackUpCodes()
    }, []);

    const getBackUpCodes = () => {
        axios.get(`${props.url_prefix}/two_factor_settings/back_up_codes`)
            .then((response) => {
                setBackUpCodes(response.data.codes)
                setRequestState(RequestState.SUCCESS)
            })
            .catch((_) => setRequestState(RequestState.ERROR))
    }
    if (request_state === RequestState.LOADING) return <div className="center"><Loader text="Chargement en cours..."/>
    </div>
    if (request_state === RequestState.ERROR) return <div className="center">Une erreur s'est produite.</div>
    if (request_state === RequestState.SUCCESS) return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: 20}}>
            <p>Veuillez conserver précieusement ces codes de recupération dans le cas où
                vous perdriez l'accès à votre authentificateur :
            </p>
            <table className="striped bordered" style={{columns: 3}}>
                <tbody>
                {[0, 1, 2, 3, 4].map((_, index) => {
                    return <tr key={index}>
                        {back_up_codes.slice(index * 2, index * 2 + 2).map((code, code_index) => <td
                            key={code_index}>{code}</td>)}
                    </tr>
                })}
                </tbody>
            </table>
            <div className="center">
                <a className="btn blue darken-2 z-depth-0" onClick={() => window.location.reload()}>J'ai enregistré mes
                    codes</a>
            </div>
        </div>

    )
}

function Deactivate2FA(props) {
    const [password, setPassword] = useState("")
    const [show_password, setShowPassword] = useState(false)
    const [disabled_2fa, setDisabled2FA] = useState(false)

    const deactivate_2fa = () => {
        let data = {password: password}
        axios.delete(`${props.url_prefix}/two_factor_settings/`, {data: data})
            .then((_) => {
                toast.success("2FA désactivée.", {autoClose: 1500})
                setDisabled2FA(true)
                setTimeout(() => props.setTwoFaStatus(TWO_FA_STATUS.DEACTIVATED), 1500)
            })
            .catch((_) => toast.error("Erreur lors de la désactivation de la 2FA."))
    }

    if (disabled_2fa) return <div className="center">
        Votre authentification à deux facteurs à été désactivée avec succès.
    </div>
    return (
        <div className="center">
            <div style={{textAlign: "left"}}>
                Les codes de récupération ont déjà été générés et consultés.<br/>
                Pour en générer de nouveaux, veuillez désactiver puis réactiver l'authentification à deux facteurs.
            </div>
            <br/>
            <div style={{textAlign: "left", fontStyle: "italic", fontWeight: 600}}>
                Pour désactiver la double authentification, veuillez renseigner votre mot de passe actuel :
            </div>
            <br/>
            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                <div className="input-field outlined" style={{width: "50%"}}>
                    <input id="2fa_password"
                           type={show_password ? "text" : "password"}
                           onChange={(e) => setPassword(e.target.value)}/>
                    <label htmlFor="2fa_password">Entrez votre mot de passe actuel</label>
                    <div id="hoverable">
                        <i className="material-icons"
                           onClick={() => setShowPassword(!show_password)}
                           style={{position: "absolute", right: "5%", top: "30%"}}
                        >{show_password ? "visibility" : "visibility_off"}</i>
                    </div>
                </div>
                <a className={`btn blue darken-2 z-depth-0 ${password ? "" : "disabled"}`}
                   style={{
                       width: "40%",
                       height: "50px",
                       display: "flex",
                       alignItems: "center",
                       justifyContent: "center"
                   }}
                   onClick={deactivate_2fa}>
                    <div style={{display: "flex"}}>
                        <i className="material-icons-outlined" style={{marginRight: "0.5vw"}}>remove_moderator</i>
                        Désactiver la double authentification
                    </div>
                </a>
            </div>
        </div>
    )
}