import React, {Component} from "react";
import Tooltip from "../../Shared/Tootip";
import BillingPreview from "./BillingPreview";
import BrandedElement from "../../Shared/BrandedElement";
import {InvoiceStatus} from "../../Shared/Enums/Enums";
import {wrapper} from "../../I18n/i18n";

const _ = require('lodash')
const {t} = wrapper("ORTHOPTIST_BILLING.BILLING_OVERVIEW")
export default class Overview extends Component {
    constructor(props) {
        super(props);
        this.renderLine = this.renderLine.bind(this)
        this.renderStatus = this.renderStatus.bind(this)
    }

    renderStatus(invoice) {
        switch (invoice.status) {
            case InvoiceStatus.WAITING:
                return <Tooltip id={`tooltip-invoice-waiting-${invoice.id}`} text={t("TOOLTIP.WAITING")}>
                    <i className="material-icons grey-text">schedule</i>
                </Tooltip>
            case InvoiceStatus.PENDING:
                return <Tooltip id={`tooltip-invoice-pending-${invoice.id}`} text={t("TOOLTIP.PENDING")}>
                    <i className="material-icons orange-text">pending</i>
                </Tooltip>
            case InvoiceStatus.SUCCESS:
                return <Tooltip id={`tooltip-invoice-success-${invoice.id}`} text={t("TOOLTIP.SUCCESS")}>
                    <i className="material-icons green-text">done</i>
                </Tooltip>
            case InvoiceStatus.FAILED:
                return <Tooltip id={`tooltip-invoice-error-${invoice.id}`} text={t("TOOLTIP.FAILED")}>
                    <i className="material-icons red-text">error</i>
                </Tooltip>
            default: return null
        }
    }

    renderLine(invoice, index) {
        const ophtalmo_earnings = Object.entries(invoice.ophtalmologists_earnings??{})
        let total_ophtalmo = !_.isEmpty(ophtalmo_earnings) ? ophtalmo_earnings.reduce((acc, [key, value]) => acc + parseFloat(value), 0) : invoice.total_ophtalmologiste/100.0
        total_ophtalmo = invoice.v2 ? total_ophtalmo : 0
        const debt = invoice.v2 && !_.isEmpty(ophtalmo_earnings) ? (invoice.synthese.payed_debt) || 0 : 0
        const total = invoice.totalPlateforme + debt

        return (
            <div style={{display: "flex", gap: 10}}>
                <div style={{flex: 1}}>
                    <div className="row valign-wrapper" style={{margin: 0}}>
                        <div className="col s1">{this.renderStatus(invoice)}</div>
                        <div className="col s3">
                            {`${((total) / 100 + total_ophtalmo).toFixed(2)}€`}
                        </div>
                        <div className="col s8">
                            {t("BILLED_THE", {date: new Date(invoice.created_at).toLocaleDateString()})}
                        </div>
                    </div>
                </div>
                <div>
                    <a className="btn-small blue darken-2 z-depth-0" target="_blank"
                       href={`/orthoptistes/paiements/invoice/documents/${invoice.id}`}>
                        <i className="material-icons left">receipt_long</i>
                        {t("GO_TO_INVOICES")}
                    </a>
                </div>
            </div>

        )
    }

    renderInvoices() {
        if (_.isEmpty(this.props.invoices)) return <h2 className="center">Aucune facture n'est disponible
            actuellement.</h2>
        return (
            <div className="container">
                <h1 className="center" id="main-title">Factures</h1>
                <ul className="collection" style={{borderRadius: "20px"}}>
                    <div style={{paddingTop: "10px"}}/>
                    {this.props.invoices.map((invoice, index) =>
                        <li key={index} className="collection-item">{this.renderLine(invoice, index)}</li>
                    )}
                </ul>
            </div>
        )
    }

    render() {
        return (
            <BrandedElement temeoo>
                <div>
                    <BillingPreview/>
                </div>
                <div>
                    {this.renderInvoices()}
                </div>
            </BrandedElement>

        )
    }
}

Overview.defaultProps = {
    invoices: []
}