import React from 'react';
import MesureOverview from "../MesureOverview";
import {Mesures, TypesMesures} from "../../../../Shared/Enums/Enums";
import PrescriptionHistory from "./PrescriptionHistory";
import BrandedElement from "../../../../Shared/BrandedElement";
import MesureStore from "../../MesureStore";
import {is_appointment_recent} from "../ImportLastAppointmentModal";
import Comments from "./Comments";
import Measures from "./Measures";

function AppointmentHistory({appointment, is_last_appointment}) {
    const [expanded, setExpanded] = React.useState(false);

    const handleImport = (event) => {
        event.stopPropagation();
        MesureStore.is_appointment_new = true
    }

    const renderPrescriptions = () => {
        return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            {appointment.prescriptions.map((prescription, index) => {
                if (!prescription?.text) return null;
                return <div key={`prescription_${appointment.id}_${index}`}>
                    <PrescriptionHistory prescription={prescription}
                                         number={appointment.prescriptions.length > 1 ? index + 1 : ""}/>
                </div>
            })}
        </div>
    }

    const renderDetails = () => {
        if (!expanded) return null;
        return <>
            <Comments appointment={appointment}/>
            <div style={{display: "flex", flexDirection: "column", fontSize: 14}}>
                <Measures appointment={appointment}/>
                {renderPrescriptions()}
            </div>
        </>
    }

    const renderImportButton = () => {
        if (!is_last_appointment) return null;
        if (!is_appointment_recent(appointment)) return null;

        return <a className="btn-small btn-flat transparent" title="Importer les données de la dernière consultation"
                  onClick={handleImport}>
            <i className={`material-icons`}>publish</i>
        </a>
    }

    return <div className="blue lighten-5" style={{borderRadius: 4, border: "thin solid #1976d2", padding: 10}}>
        <div className="valign-wrapper clickable" onClick={() => setExpanded(!expanded)}>
            <h6 style={{fontWeight: "bold"}}>Examen du : {Helper.formatDate(appointment.start)}</h6>
            <div className="valign-wrapper" style={{marginLeft: "auto"}}>
                <BrandedElement temeoo>{renderImportButton()}</BrandedElement>
                <a className="btn-small btn-flat transparent" style={{padding: 0}}>
                    <i className={`material-icons ${expanded ? "flipped" : ""}`}
                       onClick={() => setExpanded(!expanded)}>expand_more</i>
                </a>
            </div>
        </div>
        {renderDetails()}
    </div>
}

AppointmentHistory.defaultProps = {
    is_last_appointment: false
}

export default AppointmentHistory;