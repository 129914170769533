import React, {useState} from "react"
import axios from "axios";
import {toast} from "react-toastify";
import OrthoptistDocuments from "./OrthoptistDocuments";


const sortOrthoptists = (orthoptists) => {
    return orthoptists.sort((orthoptist_a, orthoptist_b) => {
        let orthoptist_a_full_signed = orthoptist_a.documents.every((document) => {
            return document.user_signatures.every((signature) => signature.date !== null)
        })

        let orthoptist_b_full_signed = orthoptist_b.documents.every((document) => {
            return document.user_signatures.every((signature) => signature.date !== null)
        })

        if (orthoptist_a_full_signed === orthoptist_b_full_signed) return 0
        if (orthoptist_a_full_signed && !orthoptist_b_full_signed) return 1
        return -1
    })
}

export default function OfficeDocuments(props) {

    const [office_location, setOfficeLocation] = useState({
        previous: props.ophtalmologist.city,
        current: props.ophtalmologist.city
    })

    const updateOfficeLocalization = () => {
        axios.patch(`/office_location/${props.office.office_id}`, {city: office_location.current})
            .then((_) => {
                let temp_location = {...office_location}
                temp_location.previous = temp_location.current
                setOfficeLocation(temp_location)
                toast.success("Enregistrement réussi.")
            })
            .catch((_) => toast.error("Erreur lors de l'enregistrement."))
    }

    const handleLocalizationChange = (new_location) => {
        let temp_localization = {...office_location}
        temp_localization.current = Helper.capitalizeFirstLetter(new_location)
        setOfficeLocation(temp_localization)
    }

    const validateLocalization = () => !!office_location.current && office_location.current.length > 0

    const localizationChanged = () => validateLocalization() && office_location.previous !== office_location.current

    return <div key={`${props.ophtalmologist.user_id}_${props.office.office_id}`}>
        <h3 className="center"
            style={{marginBottom: 5}}>
            {Helper.capitalizeFirstLetter(props.office.name) ?? "Nom du cabinet manquant"}
        </h3>
        {props.office.orthoptists.length ?
            sortOrthoptists(props.office.orthoptists).map((orthoptist) => {
                return <OrthoptistDocuments orthoptist={orthoptist}
                                            key={`${props.ophtalmologist.user_id}_${props.office.office_id}_${orthoptist.id}`}
                                            validateLocalization={validateLocalization}
                                            localizationChanged={localizationChanged}
                                            handleLocalizationChange={handleLocalizationChange}
                                            updateOfficeLocalization={updateOfficeLocalization}
                                            office={props.office}
                                            office_location={office_location}
                                            ophtalmologist={props.ophtalmologist}/>
            })
            : <div className="center">Aucun orthoptiste n'existe dans ce cabinet.</div>
        }
    </div>
}