import {Side, TypesMesures} from "../../../Shared/Enums/Enums";


const withsign = (value) => {
    if (value === null) return null
    if (value >= 0) {
        return ("+" + value)
    } else {
        return (value)
    }
}

export const formatRetinos = (mesure, side) => {
    let sphere
    let cylindre
    let axe
    let addition
    if (side === Side.LEFT) {
        sphere = withsign(mesure.og_sphere)
        cylindre = withsign(mesure.og_cylindre)
        axe = mesure.og_axe
        addition = mesure.og_addition !== null && mesure.od_addition !== 0 ? `add +${mesure.og_addition.toFixed(2)}` : ""
    } else if (side === Side.RIGHT) {
        sphere = withsign(mesure.od_sphere)
        cylindre = withsign(mesure.od_cylindre)
        axe = mesure.od_axe
        addition = mesure.od_addition !== null && mesure.od_addition !== 0 ? `add +${mesure.od_addition.toFixed(2)}` : ""
    } else return ""

    if (sphere === null || parseFloat(sphere) === 0) sphere = "plan"

    if (axe === null) axe = ""
    else axe = `${axe}°`

    if (cylindre === null) {
        cylindre = ""
        axe = ""
    } else cylindre = `(${cylindre})`

    return `${sphere} ${cylindre} ${axe} ${addition}`
}

export const formatAcuities = (mesure, side, ortho = false) => {
    const av_to_av_with_strongness = (av) => {
        if (!av) return ""
        if(!parseFloat(av)) return av

        let strongness = 0
        if (av.match(/^\d+[.,]?\d*F$/)) strongness = 1
        if (av.match(/^\d+[.,]?\d*f$/)) strongness = -1

        // Ortho => "acuity /10"
        if (strongness !== 0) av = parseFloat(av.slice(0, -1)) / 10
        else av = parseFloat(av)
        let strong_text = ""
        if(strongness !== 0) strong_text = strongness === 1 ? "F" : "f"
        return (ortho ? `${av * 10}${strong_text}/10` : `${av}${strong_text}`)
    }
    let av = ""
    if (side === Side.LEFT) {
        const av_loin = av_to_av_with_strongness(mesure["og_av_loin"])
        const parinaud = mesure["og_parinaud"]
        if (av_loin !== null || parinaud !== null) {
            av = `${av_loin ?? ""}${parinaud === null ? "" : `${av_loin ? ", " : ""}P` + parinaud}`
        }
    } else if (side === Side.RIGHT) {
        const av_loin = av_to_av_with_strongness(mesure["od_av_loin"])
        const parinaud = mesure["od_parinaud"]
        if (av_loin !== null || parinaud !== null) {
            av = `${av_loin ?? ""}${parinaud === null ? "" : `${av_loin ? ", " : ""}P` + parinaud}`
        }

    } else return ""
    return `${av}`
}

export const get_measures = (measures, measures_types) => {
    if (!measures || measures === 0) return []
    measures = measures.map(measure => {
        return {...measure, name: measures_types.find(m => m.id === measure.typedemesure_id).titre}
    })
    let tension = measures.find(m => m.name === TypesMesures.TENSION)
    if (tension) return measures
    const tensionLeft = measures.find(m => m.og_tension !== null)?.og_tension
    const tensionRight = measures.find(m => m.od_tension !== null)?.od_tension
    tension = {
        name: TypesMesures.TENSION,
        og_tension: tensionLeft,
        od_tension: tensionRight
    }
    return [tension, ...measures]
}

export const order_measures = (measures) => {
    return Object.values(TypesMesures).map(type => measures.find(m => m.name === type)).filter(m => m)
}

export const format_measures = (measures, measures_types) => {
    return order_measures(get_measures(measures, measures_types))
}