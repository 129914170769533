import test1 from '../fr.json';
import LoginPanel from './Login/LoginPanel.json';
import AskFor2FA from "./Login/AskFor2FA.json";
import NavBar from "./NavBar/NavBar.json";
import Common from './common.json';
import UserDropdown from "./NavBar/UserDropdown.json";
import ExamHistory from "./Patient/ExamHistory.json";
import PatientHistory from "./Patient/PatientHistory.json";
import PatientInfo from "./Patient/PatientInfo.json"
import Account from "./Orthoptist/Account.json";
import Users from "./Console/Users.json";
import Console from "./Console/Console.json";
import Options from "./Options/Options.json"
import GeneralStatistics from "./Statistics/Ophtalmologist/GeneralStatistics.json";
import Dashboard from "./Statistics/Orthoptist/Dashboard.json";
import BillingPreview from "./Orthoptist/BillingPreview.json";
import Phone from "./Orthoptist/Phone.json";

export default {
    ...test1,
    ...LoginPanel,
    ...Common,
    ...AskFor2FA,
    ...NavBar,
    ...UserDropdown,
    ...ExamHistory,
    ...PatientHistory,
    ...PatientInfo,
    ...Account,
    ...Users,
    ...Console,
    ...Options,
    ...GeneralStatistics,
    ...Dashboard,
    ...BillingPreview,
    ...Phone
}