import React, {useEffect, useState} from "react"
import {HashRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import GeneralTabs from "./GeneralPanels/GeneralTabs";
import {Role} from "../../Shared/Enums/Scope";
import ArsTabs from "./ArsTabs/ArsTabs";

export default function OptionRouter(props) {
    const [links_list, setLinksList] = useState([])
    const [routes_list, setRoutesList] = useState([])

    useEffect(() => renderedTabByUserType(), [])

    useEffect(() => {
        const nav = document.getElementById("options_sidenav")
        const instance = M.Sidenav.init(nav, {edge: "left", draggable: true})
        const collapsibles = document.querySelectorAll("[id=collapsibleOptionPanel]");
        const collapsible_instances = M.Collapsible.init(collapsibles, {});
        return () => {
            instance.destroy();
            collapsible_instances.forEach(collapsible_instance => {
                collapsible_instance.destroy();
            })
        }

    }, [links_list, routes_list]);

    const TABS_TYPE = {
        GENERAL: GeneralTabs,
        ARS_DOCUMENTS: ArsTabs
    }

    const Tabs_TYPE_FOR_USER = {
        "Orthoptiste": [TABS_TYPE.GENERAL, TABS_TYPE.ARS_DOCUMENTS],
        "Ophtalmologiste": [TABS_TYPE.GENERAL, TABS_TYPE.ARS_DOCUMENTS],
        "Secretaire": [TABS_TYPE.GENERAL],
        "Externe": [TABS_TYPE.GENERAL]
    }

    const URL_PREFIX = {
        "Orthoptiste": "/orthoptistes",
        "Secretaire": "/secretaires",
        "Ophtalmologiste": "/ophtalmologistes",
        "Externe": "/externes"
    }

    const renderedTabByUserType = () => {
        let rendered_links = []
        let rendered_routes = []

        Tabs_TYPE_FOR_USER[props.user_type].map((tab_type) => {
            const [routes, links] = new tab_type(props, URL_PREFIX[props.user_type]).useComponents()
            rendered_links.push(links)
            rendered_routes.push(routes)
        })

        setLinksList(rendered_links)
        setRoutesList(rendered_routes)
    }


    return (<Router>
        <div id="full-height" style={{display: "flex", overflowY: "clip"}}>
                    <div style={{
                        minWidth: "300px",
                        boxShadow: "2px 0 0 0 #E4E4E4",
                        backgroundColor: "#f6f6f6",
                    }}>
                        <ul id="options_sidenav" className="sidenav sidenav-fixed white full-height"
                            style={{overflow: "hidden", zIndex: 1, position: "absolute"}}>
                            {links_list.map((link_category) => <>
                                    {link_category}
                                    <div className="divider"/>
                                </>
                            )}
                        </ul>
                    </div>
                    <div style={{width: "100%", height: "100%", overflowY: "scroll"}}>
                        <Routes>
                            {routes_list.map((route_category) => route_category)}
                            <Route key="root_route" path="/" element={<Navigate
                                to={props.user_type === Role.SECRETARY ? "/security" : "/personal_infos"}/>}/>
                        </Routes>
                    </div>
                </div>
            <div/>
        </Router>

    )
}
