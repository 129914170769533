import React from 'react';
import {t} from "../../I18n/i18n"

function EditAppointmentButton({orthoptist_signed, appointment_id, appointment_status}) {

    const buttonContent = () => {
        if(appointment_status > 1) return {icon: "preview", text: t("EXAM_HISTORY.VIEW_CONSULTATION")}
        else return {icon: "edit", text: t("EXAM_HISTORY.EDIT_CONSULTATION")}

    }
    if(!orthoptist_signed || appointment_status > 1) return null


    return <a className="btn-small blue darken-2 z-depth-0"
               href={`/mesure/${appointment_id}`}
               title={t("EXAM_HISTORY.EDIT_WARNING")}>
                <i className="material-icons left hide-on-med-and-down">{buttonContent().icon}</i>
                <i className="material-icons hide-on-large-only">{buttonContent().icon}</i>
                <span className="hide-on-med-and-down truncate">{buttonContent().text}</span>
            </a>
}

export default EditAppointmentButton;