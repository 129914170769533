import React from "react";
import {NavLink, Route} from "react-router-dom";
import {Role} from "../../../Shared/Enums/Scope";
import OphtalmologistPanel from "./OphtalmologistPanel";
import OrthoptistPanel from "./OrthoptistPanel";

export default class ArsTabs {
    constructor(props, url_prefix) {
        this.props = props
    }

    useComponents = () => [this.routes(), this.links()]

    routes = function () {
        return (
            <>
                <Route key="arsDocumentsPage"
                       path="/ars_documents"
                       exact
                       element={this.props.user_type === Role.ORTHOPTIST ?
                           <OrthoptistPanel data={this.props}/>
                           : <OphtalmologistPanel data={this.props}/>
                       }/>
            </>
        )
    }
    links = function () {
        return (
            <li key="ars_documents" style={{paddingTop: 10}}>
                <NavLink to="/ars_documents">
                    <div className="grey-text text-darken-2 center collapsible collapsible-header"
                       style={{display: "flex", alignItems: "center"}}>
                        <i className="material-icons">edit_note</i>Documents ARS
                    </div>
                </NavLink>
            </li>
        )
    }
}