import React, {Component} from "react";
import DisplayBlocks from "./DisplayBlocks";
import {Observer} from "mobx-react";
import MarkdownEditor from "../../../Shared/UiKit/MarkdownEditor";

export default class DisplayInput extends Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this)
        this.renderText = this.renderText.bind(this)
        this.renderSelect = this.renderSelect.bind(this)
        this.state = {
            block: {},
            values: {}
        }
    }

    componentDidMount() {
        this.setState({
            block: this.props.block,
            values: this.props.values
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({
                block: this.props.block,
                values: this.props.values
            })
        }
    }

    changeValue(input, value) {
        let newValues = Object.assign({}, this.state.values)
        newValues[input.id] = value
        this.setState({
            values: newValues
        }, () => this.props.onChange(this.state.values))
    }

    renderText(input) {
        return <div style={{display: "flex", width: "100%"}}>
            <div style={{
                display: "flex", alignItems: "baseline", marginTop: 12,
                fontSize: `${DisplayBlocks.computeSize(input.size)}`,
                fontWeight: `${input.weight}`,
                fontStyle: `${input.style}`
            }}>
                {input.text}
            </div>
            <div style={{paddingLeft: 10, width: input.width ? `${input.width}px` : '100%'}}>
                {this.props.appointment_pending && this.state.values[input.id] !== null ?
                    <div className="hover-container" style={{position: "relative", padding: 10}}>
                        <MarkdownEditor
                            defaultValue={this.state.values[input.id]}
                            onChange={(v) => this.changeValue(input, v)}/>
                        <a className={`hover-button btn-small blue darken-2 z-depth-0 ${input.default_text ? "" : "hide"}`}
                           style={{position: "absolute", right: -20, top: -5, borderRadius: 20}}
                           onClick={() => this.changeValue(input, null)}>
                            <i className="material-icons">refresh</i>
                        </a>
                    </div>
                    :
                    <div style={{
                        borderRadius: "4px", border: "thin solid lightgrey",
                        width: input.width ? `${input.width}px` : '100%',
                        height: input.height ? input.height < 40 ? "fit-content" : `${input.height}px` : 'fit-content',
                        minHeight: "40px",
                        maxWidth: "100%"
                    }}>
                        <div id="display_block_html" dangerouslySetInnerHTML={{__html: input.default_text || ""}}
                             style={{justifyContent: input.justify}}
                        />
                    </div>
                }
            </div>
        </div>
    }

    renderSelect(input) {
        if (input.options) {
            return (
                <div className="valign-wrapper">
                    <div style={{
                        fontSize: `${DisplayBlocks.computeSize(input.size)}`,
                        fontWeight: `${input.weight}`,
                        fontStyle: `${input.style}`
                    }}>
                        {input.text} &nbsp;
                    </div>
                    <div style={{paddingLeft: "1vw"}}>
                        <select className="browser-default"
                                value={this.state.values[input.id] ?? ""}
                                onChange={(e) => this.changeValue(input, e.target.value)}>
                            <option value="" disabled hidden></option>
                            {input.options.map((option) =>
                                <option value={option} key={option}>{option}</option>
                            )}
                        </select>
                    </div>
                </div>
            )
        } else return null
    }

    render() {
        if (this.state.block.data) {
            return (
                <Observer>
                    {() => <div style={{display: "flex", alignItems: "stretch"}}>
                        {this.props.block.data.map((input, index) =>
                            <div key={index}
                                 style={{
                                     width: "100%",
                                     padding: "2px 1vw 2px 1vw",
                                     display: "flex",
                                     justifyContent: `${input.justify}`
                                 }}>
                                {input.type === "TEXT" ? (
                                    this.renderText(input)
                                ) : (
                                    this.renderSelect(input)
                                )}
                            </div>
                        )}
                    </div>}
                </Observer>
            );
        } else return null
    }

    static previewText(input, values) {
        return (
            <div style={{display: "flex", alignItems: "baseline", whiteSpace: "pre-wrap"}}>
                <span style={{
                    fontSize: `${DisplayBlocks.computeSize(input.size)}`,
                    fontWeight: `${input.weight}`,
                    fontStyle: `${input.style}`,
                    display: "inline",
                    whiteSpace: "nowrap"
                }}>
                    {input.text} &nbsp;
                </span>
                <div className="report-input-display" dangerouslySetInnerHTML={{__html: values[input.id] ?? ""}}
                     style={{whiteSpace: "pre-wrap", margin: 0}}/>
            </div>
        )
    }

    static previewSelect(input, values) {
        return (
            <div className="valign-wrapper">
                <div style={{
                    fontSize: `${DisplayBlocks.computeSize(input.size)}`,
                    fontWeight: `${input.weight}`,
                    fontStyle: `${input.style}`
                }}>
                    {input.text} &nbsp;
                </div>
                <div style={{whiteSpace: "pre-wrap"}}>
                    {values[input.id] ? values[input.id] : ""}
                </div>
            </div>
        )
    }

    static preview(block, values = {}) {
        if (block.data) {
            return <div style={{
                display: "flex",
                alignItems: "stretch",
                pageBreakInside: "avoid"
            }}>
                {
                    block.data.map((input, index) => {
                            if (!values[input.id] && !input.default_text) return
                            return <div key={index}
                                        style={{
                                            width: "100%",
                                            padding: "2px 1vw 2px 1vw",
                                            display: "flex",
                                            justifyContent: `${input.justify}`
                                        }}>
                                {input.type === "TEXT" ? this.previewText(input, values) : this.previewSelect(input, values)}
                            </div>
                        }
                    )
                }
            </div>
        } else return null
    }
}

DisplayInput.defaultProps = {
    block: {},
    values: {},
    appointment_pending: false
}