import React, {useEffect} from 'react';
import {getEnv} from "../Helper";
import {createPortal} from "react-dom";


function NavLi({element}) {
    if (element.show !== undefined && !element.show) {
        return null;
    }
    return (
        <li className={"white"}>
            <a href={element.url}>{element.name}</a>
        </li>
    )
}

const DropDownElementNav = ({element}) => {
    const triggerRef = React.useRef("bloup");
    useEffect(() => {
        M.Dropdown.init(triggerRef.current, {coverTrigger: false, constrainWidth: false});
    }, []);
    return <React.Fragment>
        {createPortal(
            <ul id={element.name} className="dropdown-content">
                {element.dropdown.map((sub_element) => {
                    return <NavLi key={sub_element.name} element={sub_element}/>
                })}
            </ul>
            , document.body)}
        <li className={"white"}>
            <a className="dropdown-trigger" ref={triggerRef} href={"#"} data-target={element.name}>{element.name}
                <i className="material-icons right" style={{lineHeight: "inherit"}}> arrow_drop_down</i>
            </a>
        </li>
    </React.Fragment>
}


function DropDownMobileNav({element}) {
    return (
        <React.Fragment>
            {element.dropdown.map((sub_element) => {
                    return <NavLi key={sub_element.name} element={sub_element}/>

                }
            )}
        </React.Fragment>
    )
}

export default function NavBar({image_path, urls, components = [], topbar_prop = ""}) {
    const mobileRef = React.useRef(null);
    useEffect(() => {
        new M.Sidenav(mobileRef.current, {});
    }, []);

    return (
        <nav className={topbar_prop}>
            <div className="nav-wrapper">
                <a href="/" className="brand-logo valign-wrapper" style={{height: "90px"}}>
                    {getEnv("BRAND") === "WHITE" ? (
                        <img height="70px" style="margin: 10px 2vw" src={image_path}/>
                    ) : (
                        <img height="45px" src={image_path}/>
                    )}
                </a>
                <a href="#" data-target="mobile-sidenav" className="sidenav-trigger"><i
                    className="material-icons black-text">menu</i></a>

                <ul id="nav-mobile" className="right hide-on-med-and-down">
                    {urls.map((element) => {
                        if (element.dropdown) {
                            return (
                                <DropDownElementNav key={element.name} element={element}/>
                            )
                        } else {
                            return (
                                <NavLi key={element.name} element={element}/>
                            )
                        }

                    })}
                    {components.map((component) => {
                        return (
                            <li key={component.dataCy} className={"white"}
                                dataCy={component.dataCy}>{component.component}</li>
                        )
                    })}
                </ul>
            </div>
            {/*mobile*/}
            <ul className="sidenav" id="mobile-sidenav" ref={mobileRef}>
                {urls.map((element) => {
                    if (element.dropdown) {
                        return (
                            <DropDownMobileNav key={element.name} element={element}/>
                        )
                    } else {
                        return (
                            <NavLi key={element.name} element={element}/>
                        )
                    }

                })}


            </ul>
        </nav>
    )


}