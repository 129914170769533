import React, {useEffect, useState} from "react"
import axios from "axios";
import {ArsDocumentsType} from "../../../Shared/Enums/ArsDocumentsType";
import {generatePdfIframe} from "../../../Shared/PDF/PDFManager";
import Loader from "../../Controle/Loader";
import {toast} from "react-toastify";
import Helper from "../../../Shared/Helper";


const sortAndFormatDocuments = (document_list) => {
    let temp_document_list = document_list.map((document) => {
        let temp_document = {...document}
        temp_document.iframe = null
        return temp_document
    })
    return temp_document_list.sort((document_a, document_b) => {
        let a_full_signed = document_a.user_signatures.every((signature) => signature.date !== null)
        let b_full_signed = document_b.user_signatures.every((signature) => signature.date !== null)

        if (a_full_signed === b_full_signed) return 0
        if (a_full_signed && !b_full_signed) return 1
        return -1
    })
}


export default function OrthoptistDocuments(props) {

    const [collapsible_instances, setCollapsibleInstances] = useState([])
    const [documents, setDocuments] = useState(sortAndFormatDocuments(props.orthoptist.documents))

    const signDocument = (document_id) => {
        axios.patch(`/signDocument/${document_id}`)
            .then((_) => {
                let modified_document
                let temp_documents = documents.map((document) => {
                    if (document.id === document_id) {
                        let signature_index = document.user_signatures.findIndex(
                            (signature) => signature.user_id === props.ophtalmologist.user_id)
                        document.user_signatures[signature_index].date = new Date()
                        modified_document = document
                    }
                    return document
                })
                handlePdf(modified_document, true)
                setDocuments(temp_documents)
                toast.success("Votre document est désormais signé.")
            })
            .catch((error) => toast.error("Erreur lors de la signature") && console.log(error))

    }

    useEffect(() => {
        let elements = document.querySelectorAll("[id=arsDocumentDropdown]")
        setCollapsibleInstances(M.Collapsible.init(elements, {}))
        return () => collapsible_instances.map((instance) => instance.destroy())
    }, [])


    const handlePdf = async (modified_document, ignore_existing_iframe = false) => {

        if (modified_document.iframe && !ignore_existing_iframe) return
        let signature_count = modified_document.user_signatures.map((signature) => signature.date !== null).filter(Boolean).length

        let signature_date
        if (signature_count === modified_document.user_signatures.length) {
            signature_date = modified_document.user_signatures.reduce((a, b) => {
                return new Date(a.date) > new Date(b.date) ? a : b;
            })
            signature_date = signature_date.date
        }
        if (modified_document.user_signatures.length - signature_count === 1) signature_date = new Date()

        let signature_ophtalmologist = Helper.toCamelCase(`${props.ophtalmologist.first_name} ${props.ophtalmologist.last_name}`) ?? ""
        let signature_orthoptist = Helper.toCamelCase(`${props.orthoptist.first_name} ${props.orthoptist.last_name}`) ?? ""

        let pdf_data = {}
        let document_uri
        let document_title
        switch (modified_document.document_type) {
            case ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.document_type:
                document_uri = ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.url
                document_title = ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.title
                break
            case ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.document_type:
                document_uri = ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.url
                document_title = ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.title
                break
            case ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.document_type:
                document_uri = ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.url
                document_title = ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.title
                break
            case ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.document_type:
                document_uri = ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.url
                document_title = ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.title
                break
            case ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.document_type:
                document_uri = ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.url
                document_title = ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.title
                break
            case ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.document_type:
                document_uri = ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.url
                document_title = ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.title
                break
            case ArsDocumentsType.EMPLOYER_ACCORD_6_15.document_type:
                document_uri = ArsDocumentsType.EMPLOYER_ACCORD_6_15.url
                document_title = ArsDocumentsType.EMPLOYER_ACCORD_6_15.title
                break
            case ArsDocumentsType.EMPLOYER_ACCORD_16_50.document_type:
                document_uri = ArsDocumentsType.EMPLOYER_ACCORD_16_50.url
                document_title = ArsDocumentsType.EMPLOYER_ACCORD_16_50.title
                break
            case ArsDocumentsType.EMPLOYER_ACCORD_RETINO.document_type:
                document_uri = ArsDocumentsType.EMPLOYER_ACCORD_RETINO.url
                document_title = ArsDocumentsType.EMPLOYER_ACCORD_RETINO.title
                break
            case ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.document_type:
                document_uri = ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.url
                document_title = ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.title
                break
            default:
                break
        }

        switch (modified_document.document_type) {
            case ArsDocumentsType.EMPLOYER_ACCORD_6_15.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_16_50.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_RETINO.document_type:
                pdf_data = {
                    nom_employeur: Helper.toCamelCase(props.office.director_fullname) ?? "",
                    nom_ophtalmologiste1: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    ville_employeur: props.office.city ?? "",
                    nom_structure: props.office.name ?? "",
                    nom_orthoptiste1: Helper.formatName(props.orthoptist.first_name, props.orthoptist.last_name) ?? "",
                    date: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_employeur: Helper.toCamelCase(props.office.director_fullname) ?? ""
                }
                break

            case ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.document_type:
                pdf_data = {
                    nom_ophtalmo1: Helper.capitalizeFirstLetter(props.ophtalmologist.last_name) ?? "",
                    prenom_ophtalmo1: Helper.capitalizeFirstLetter(props.ophtalmologist.first_name) ?? "",
                    profession_delegant: "Ophtalmologiste",
                    profession_delegue: "Orthoptiste",
                    ville_ophtalmo: props.office_location.current ?? "",
                    rpps_ophtalmo1: props.ophtalmologist.rpps ?? "",
                    adeli_ortho1: props.orthoptist.rpps ?? "",
                    prenom_ortho1: Helper.capitalizeFirstLetter(props.orthoptist.first_name) ?? "",
                    nom_ortho1: Helper.capitalizeFirstLetter(props.orthoptist.last_name) ?? "",
                    date: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ophtalmo1: signature_ophtalmologist,
                    signature_ortho1: signature_orthoptist
                }
                break

            case ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.document_type:
                pdf_data = {
                    nom_ophtalmologiste1: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    nom_ophtalmologiste1b: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    nom_orthoptiste1: Helper.formatName(props.orthoptist.first_name, props.orthoptist.last_name) ?? "",
                    ville_ophtalmo: props.office_location.current ?? "",
                    date: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ophtalmo1: signature_ophtalmologist
                }
                break
            case ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.document_type:
                pdf_data = {
                    nom_ophtalmologiste1: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    ville_ophta: props.office_location.current ?? "",
                    date_ophta: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ophta: signature_ophtalmologist,
                    nom_orthoptiste1: Helper.formatName(props.orthoptist.first_name, props.orthoptist.last_name) ?? "",
                    nom_ophtalmologiste1b: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    ville_ortho: props.office_location.current ?? "",
                    date_ortho: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ortho: signature_orthoptist
                }
                break
            default:
                break
        }

        await generatePdfIframe(document_uri, document_title, pdf_data)
            .then((iframe_doc) => {
                    let modified_array = documents.map((document) => {
                        if (document.id === modified_document.id) {
                            return {...document, iframe: iframe_doc}
                        }
                        return document
                    })
                    setDocuments(modified_array)
                }
            )
            .catch((error) => console.log(error))
    }

    const getOphtalmologistSignature = (document) => {
        return document.user_signatures.find((signature) => signature.user_id === props.ophtalmologist.user_id)
    }

    const getOrthoptistSignature = (document) => {
        return document.user_signatures.find((signature) => signature.user_id === props.orthoptist.user_id)
    }

    const isDocumentSigned = (document) => {
        let ophtalmologist_signature = getOphtalmologistSignature(document)
        let orthoptist_signature = getOrthoptistSignature(document)
        if ((orthoptist_signature && orthoptist_signature.date)
            && ophtalmologist_signature && ophtalmologist_signature.date) {
            return <i className="material-icons green-text">check_circle</i>
        }
        if (!(orthoptist_signature && orthoptist_signature.date)
            && ophtalmologist_signature && ophtalmologist_signature.date) {
            return <i className="material-icons">hourglass_empty</i>
        } else {
            return <i className="material-icons red-text">cancel</i>
        }
    }

    const getMissingElements = (document_type) => {
        let missing_infos = []
        switch (document_type) {
            case ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.document_type:
                if (!props.ophtalmologist.last_name) missing_infos.push("Votre nom de famille")
                if (!props.ophtalmologist.first_name) missing_infos.push("Votre prénom")
                if (!props.orthoptist.last_name?.length) missing_infos.push("Le nom de famille de l'orthoptiste")
                if (!props.orthoptist.first_name?.length) missing_infos.push("Le prénom de l'orthoptiste")
                if (!props.office_location.current) missing_infos.push("L'adresse du cabinet")
                break
            case ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.document_type:
                if (!props.ophtalmologist.last_name) missing_infos.push("Votre nom de famille")
                if (!props.ophtalmologist.first_name) missing_infos.push("Votre prénom")
                if (!props.orthoptist.last_name) missing_infos.push("Le nom de famille de l'orthoptiste")
                if (!props.orthoptist.first_name) missing_infos.push("Le prénom de l'orthoptiste")
                if (!props.office_location.current) missing_infos.push("L'adresse du cabinet")
                if (!props.ophtalmologist.rpps) missing_infos.push("Votre numéro RPPS")
                if (!props.orthoptist.adeli) missing_infos.push("Votre numéro Adeli")
                break
            case ArsDocumentsType.EMPLOYER_ACCORD_6_15.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_16_50.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_RETINO.document_type:
                if (!props.ophtalmologist.last_name) missing_infos.push("Votre nom de famille")
                if (!props.ophtalmologist.first_name) missing_infos.push("Votre prénom")
                if (!props.office.director_fullname) missing_infos.push("Le nom du directeur du cabinet")
                if (!props.orthoptist.last_name) missing_infos.push("Le nom de famille de l'orthoptiste")
                if (!props.orthoptist.first_name) missing_infos.push("Le prénom de l'orthoptiste")
                if (!props.office.name) missing_infos.push("Le titre du cabinet")
            default:
                break
        }
        return missing_infos
    }

    const renderOphtalmologistSignatureInfos = (document) => {
        let ophtalmologist_signature = getOphtalmologistSignature(document)
        if (ophtalmologist_signature.date !== null) {
            return <div style={{display: "flex", alignItems: "center"}}>
                <i className="material-icons-outlined green-text" style={{marginRight: 10}}>check_circle</i>
                <div>
                    Votre signature:<br/>
                    Signé le {Helper.formatDate(ophtalmologist_signature.date)}
                </div>
            </div>
        }
        return <div style={{display: "flex", alignItems: "center"}}>
            <i className="material-icons-outlined red-text" style={{marginRight: 10}}>cancel</i>
            <div>
                Votre signature:<br/>
                En attente de votre signature.
            </div>
        </div>
    }

    const renderOrthoptistSignatureInfos = (document) => {
        let orthoptist_signature = getOrthoptistSignature(document)
        let ophtalmologist_signature = getOphtalmologistSignature(document)

        if (orthoptist_signature && orthoptist_signature.date !== null) {
            return <div style={{display: "flex", alignItems: "center"}}>
                <i className="material-icons-outlined green-text" style={{marginRight: 10}}>check_circle</i>
                <div>
                    Signature de l'orthoptiste:<br/>
                    Signé le {Helper.formatDate(orthoptist_signature.date)}
                </div>
            </div>
        }
        if ((orthoptist_signature && orthoptist_signature.date !== null)
            && (ophtalmologist_signature && ophtalmologist_signature.date !== null)) {
            return <div style={{display: "flex", alignItems: "center"}}>
                <i className="material-icons-outlined red-text" style={{marginRight: 10}}>cancel</i>
                <div>
                    Signature de l'orthoptiste:<br/>
                    En attente de la signature.
                </div>
            </div>
        }
        return <div style={{display: "flex", alignItems: "center"}}>
            <i className="material-icons-outlined" style={{marginRight: 10}}>hourglass_empty</i>
            <div>
                Signature de l'orthoptiste:<br/>
                En attente de la signature.
            </div>
        </div>
    }

    const renderSignatureButton = (document) => {
        let signature_ophtalmologist = getOphtalmologistSignature(document)
        if (signature_ophtalmologist && signature_ophtalmologist.date) {
            return <a className="btn blue darken-2 z-depth-0 disabled"
                      style={{display: "flex", alignItems: "center", width: "fit-content", alignSelf: "center"}}>
                <i className="material-icons-outlined" style={{marginRight: 5}}>check_circle</i>
                <div>Déjà signé</div>
            </a>
        }
        let missing_data = getMissingElements(document.document_type)
        if (missing_data.length) {
            return <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: 10,
                marginLeft: 20
            }}>
                Des informations sont manquantes avant de permettre la signature:
                {missing_data.map((element) => <div>- {element}<br/></div>)}
            </div>
        }
        return <a className="btn blue darken-2 z-depth-0"
                  onClick={() => signDocument(document.id)}
                  style={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                      alignSelf: "center",
                      marginTop: 10
                  }}>
            <i className="material-icons" style={{marginRight: 5}}>edit_note</i>
            <div>Signer le document</div>
        </a>
    }

    const renderIframe = (iframe) => {
        if (iframe) {
            return <div style={{height: "500px", marginTop: 20, marginBottom: 20}}>
                {iframe}
            </div>
        }
        return <div className="center"><Loader wide text="Chargement du document..."/></div>
    }

    return <div key={`${props.office_id}_${props.orthoptist.id}`}>
        <h5>{Helper.formatName(props.orthoptist.first_name, props.orthoptist.last_name)}</h5>
        <ul className="collapsible" id="arsDocumentDropdown">
            {documents.map((document) => {
                return (
                    <li key={`${props.office.office_id}_${props.orthoptist.id}_${document.id}`}>
                        <div className="collapsible-header"
                             onClick={() => handlePdf(document)}>
                            {isDocumentSigned(document)}
                            {ArsDocumentsType[document.document_type].title}
                        </div>
                        <div className="collapsible-body">
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <div style={{display: "flex", justifyContent: "space-around"}}>
                                    {renderOphtalmologistSignatureInfos(document)}
                                    {renderOrthoptistSignatureInfos(document)}
                                </div>
                                {renderIframe(document.iframe)}
                                <div style={{display: "flex", gap: 20}}>
                                    <div className="input-field outlined" style={{width: "50%"}}>
                                        <input type="text"
                                               value={Helper.capitalizeFirstLetter(props.office_location.current) ?? ""}
                                               onChange={(e) => props.handleLocalizationChange(e.target.value)}
                                        />
                                        <label htmlFor="email">Lieu de signature</label>
                                    </div>
                                    <div className="red-text"
                                         style={{
                                             display: props.validateLocalization(props.office_location.current) ? "none" : "flex",
                                             alignItems: "center",
                                             gap: "0.5vw"
                                         }}>
                                        <i className="material-icons">error</i>
                                        Veuillez saisir une lieu de signature.
                                    </div>

                                    <a style={{
                                        display: props.localizationChanged() ? "flex" : "none",
                                        gap: 5
                                    }}
                                       className="btn blue darken-2 z-depth-0"
                                       onClick={props.updateOfficeLocalization}>
                                        <i className="material-icons-outlined">save</i>
                                        Enregistrer le lieu de signature
                                    </a>
                                </div>
                                {renderSignatureButton(document)}
                            </div>
                        </div>
                    </li>
                )
            })}
        </ul>
    </div>
}