import PersonalInformationPanel from "./PersonalInformationPanel";
import SecurityPanel from "./SecurityPanel";
import TwoFactorAuthPanel from "./TwoFactorAuthPanel";
import React from "react";
import {NavLink, Route} from "react-router-dom";
import {Role} from "../../../Shared/Enums/Scope";
import {t} from "../../../I18n/i18n";
import OfficePanel from "./OfficePanel";

export default class GeneralTabs {

    constructor(props, url_prefix) {
        this.props = props
        this.DEFAULT_TABS = [
            {
                name: t("OPTIONS.PERSONAL_INFORMATIONS"),
                path: "/personal_infos",
                icon: "account_circle",
                disabled: false,
                user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.EXTERN],
                component: <PersonalInformationPanel
                    user_type={this.props.user_type}
                    url_prefix={url_prefix}
                    rpps={this.props.rpps}
                    phone_number={this.props.phone_number}
                    first_name={this.props.first_name}
                    last_name={this.props.last_name}
                    adeli={this.props.adeli}
                    full_name={this.props.full_name}
                    invoice_label={this.props.invoice_label}
                    address={this.props.address}
                    city={this.props.city}
                    postal_code={this.props.postal_code}
                />
            }, {
                name: t("OPTIONS.OFFICE.TITLE"),
                path: "/office",
                icon: "home",
                disabled: false,
                user_types: [Role.ORTHOPTIST],
                component: <OfficePanel url_prefix={url_prefix} office={props.office}/>
            }, {
                name: t("OPTIONS.SECURITY"),
                path: "/security",
                icon: "security",
                disabled: false,
                user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.SECRETARY, Role.EXTERN],
                component: <SecurityPanel url_prefix={url_prefix}/>
            }, {
                name: t("OPTIONS.2FA_HANDLING"),
                path: "/two_factor_auth",
                icon: "qr_code_scanner",
                disabled: false,
                user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.SECRETARY],
                component: <TwoFactorAuthPanel
                    url_prefix={url_prefix}
                    activated={this.props.two_fa_activated}
                    code_generated={this.props.two_fa_code_generated}
                />
            }
        ]
    }

    useComponents = () => [this.routes(), this.links()]

    routes = function () {
        return (
            <>
                {this.DEFAULT_TABS.map((tab_route, index) => {
                    if(!tab_route.user_types.includes(this.props.user_type)) return null
                    return <Route key={`general_option_route_${index}`} path={tab_route.path} exact
                                  element={tab_route.component}/>
                })}
            </>
        )
    }

    links = function () {
        return <li className="no-padding" key="general_tab">
            <ul className="collapsible collapsible-accordion" id="collapsibleOptionPanel" data-cy="GeneralOptionsDropDown">
                <li key="general_tab_title" className="active">
                    <a className="collapsible-header grey-text text-darken-2">
                        {t("OPTIONS.GENERAL_OPTIONS")}
                        <i className="material-icons">arrow_drop_down</i>
                    </a>
                    <ul className="collapsible-body">
                        {this.DEFAULT_TABS.map((tab, index) => {
                            if (tab.disabled) return null
                            if(!tab.user_types.includes(this.props.user_type)) return null
                            return <li key={`general_option_${index}`} data-cy="GeneralOptionsLinks" className="navlink">
                                <NavLink to={tab.path}
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                      }}>
                                    <i className="material-icons grey-text text-darken-2 secondary_content">{tab.icon}</i>
                                    <h6 className="hide-on-small-only">{tab.name}</h6>
                                </NavLink>
                            </li>
                        })}
                    </ul>
                </li>
            </ul>
        </li>
    }
}
