import React, {Component} from 'react';
import OrthoptistePatients from './OrthoptistePatients';
import SecretariatPatients from './SecretariatPatients';
import axios from 'axios'
import {toast} from "react-toastify";

const _ = require('lodash');
export default class Patients extends Component {
    constructor(props) {
        super(props);
        this.toggleArchive = this.toggleArchive.bind(this)
        this.search = this.search.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.searchDebounce = _.debounce(() => this.search(false), 250)
        this.deletePatientHandler = this.deletePatientHandler.bind(this)
        this.state = {
            q: "",
            patients: [],
            archive: false,
            page: 0
        };
    }

    search(unchanged = true) {
        axios.get(`/api/v2/search/patients?q=${this.state.q}&archive=${this.state.archive}&page=${this.state.page}`)
            .then((response) => {
                if (unchanged) this.setState({
                    patients: this.state.patients.concat(response.data.patients),
                    page: this.state.page + 1
                })
                else this.setState({patients: response.data.patients, page: 1})
            })
            .catch((error)=>{})
    }

    handleChange(e) {
        this.setState({
            q: e.target.value,
            page: 0
        }, ()=>this.searchDebounce())

    }

    deletePatientHandler(patientId) {
        axios.delete(`/patients/${patientId}`)
            .then(response => {
                const patients = this.state.patients.filter(i => i.id !== patientId)
                this.setState({patients: patients})
            })
            .catch(error => {
            })
    }

    componentDidMount() {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
    }

    toggleArchive(e) {
        this.setState({archive: !this.state.archive})
        this.searchDebounce()
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="card">
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 40,
                            margin: "0 20px",
                            flexWrap: "wrap"
                        }}>
                            <div className="input-field outlined" style={{minWidth: "30%"}}>
                                <input type="text" id="q" value={this.state.q}
                                       onChange={this.handleChange}
                                       autoComplete="off"
                                       autoFocus
                                       data-cy="SearchBarPatient"/>
                                <label htmlFor="q">Recherche</label>
                            </div>
                            <div className="input-field outlined" style={{minWidth: "30%"}}>
                                <select
                                    id="archive"
                                    value={this.state.archive}
                                    onChange={this.toggleArchive}
                                >
                                    <option value={true}>Archivé</option>
                                    <option value={false}>Actif</option>
                                </select>
                                <label>Etat du dossier</label>
                            </div>
                            <div className="input-field" style={{marginLeft: "auto"}}>
                                <a className="btn blue darken-2 z-depth-0"
                                   href="/patients/new"
                                   data-cy="CreateNewPatientButton">
                                    Nouveau patient<i className="material-icons left">person_add</i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.isForOrthoptiste ?
                    <OrthoptistePatients patients={this.state.patients} search={this.search}/> :
                    <SecretariatPatients patients={this.state.patients} search={this.search}/>}

            </div>
        );
    }
}
