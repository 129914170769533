import React from 'react';
import {t} from "../../I18n/i18n";
import {toast} from "react-toastify";
import axios from "axios";

const ValidateEmail = ({name, email}) => {
    const password_ref = React.useRef(null);
    const password_confirmation_ref = React.useRef(null);
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content

    const submit = () => {
        const password = password_ref.current.value;
        const password_confirmation = password_confirmation_ref.current.value;

        if (password !== password_confirmation) {
            toast.error(t("ACCOUNT.VALIDATION.PASSWORD.NO_MATCH"))
            return
        }

        if (password.length < 8) {
            toast.error(t("ACCOUNT.VALIDATION.PASSWORD.TOO_SHORT"))
            return
        }

        axios.patch("/account/create", {
            password: password,
            email: email
        }).then((_) => {
            window.location.href = "/"
        }).catch((_) => {
            toast.error(t("ACCOUNT.VALIDATION.ERROR"))
        })
    }

    return <div className="container" style={{
        maxWidth: 600,
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: 50,
        alignItems: "center"
    }}>
        <h3 className="center-align"
            style={{margin: 0, whiteSpace: "pre-wrap"}}>{t("ACCOUNT.VALIDATION.TEXT", {name: name})}</h3>
        <label>{t("ACCOUNT.VALIDATION.DESCRIPTION")}</label>
        <div className="row" style={{width: "100%", gap: 10}}>
            <h6 className="col s12 valign-wrapper" style={{margin: 0}}>{t("PASSWORD")}</h6>
            <div className="input-field outlined col s12">
                <input type="password" className="form-control" ref={password_ref}/>
            </div>
        </div>
        <div className="row" style={{width: "100%", gap: 10}}>
            <h6 className="col s12 valign-wrapper" style={{margin: 0}}>{t("REPEAT_PASSWORD")}</h6>
            <div className="input-field outlined col s12">
                <input type="password" className="form-control" ref={password_confirmation_ref}/>
            </div>
        </div>
        <div>
            <a className="btn blue darken-2 z-depth-0" onClick={submit}>
                {t("SUBMIT")}
            </a>
        </div>
    </div>
}

export default ValidateEmail;