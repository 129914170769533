import React, {useEffect, useState} from "react";
import MonoStatDisplay from "../MonoStatDisplay";
import BarChart from "../BarChart";
import axios from "axios";
import {toast} from "react-toastify";
import Tooltip from "../../../Shared/Tootip";
import Helper from "../../../Shared/Helper";
import Loader from "../../../Shared/Loader";
import {t} from "../../../I18n/i18n"

function Dashboard(props) {
    const [earnings, setEarnings] = useState({labels: [], datasets: undefined})
    const [dailyAppointments, setDailyAppointments] = useState(undefined)
    const [unreadMessages, setUnreadMessages] = useState(undefined)
    const [alerts, setAlerts] = useState(undefined)

    useEffect(() => {
        getOrthoptistEarnings()
        getDailyAppointments()
        getUnreadMessages()
        getAlerts()
    }, [])

    const getDailyAppointments = () => {
        axios.get("/admin/statistiques/api/orthoptist/appointments/daily")
            .then(resp => {
                setDailyAppointments(resp.data.number)
            }).catch(() => toast.error(t("ORTHOPTIST_DASHBOARD.TOAST.ERROR.NB_APPOINTMENTS")))
    }

    const getUnreadMessages = () => {
        axios.get("/admin/statistiques/api/orthoptist/appointment/unread")
            .then(resp => {
                setUnreadMessages(resp.data.messages)
            }).catch(() => toast.error(t("ORTHOPTIST_DASHBOARD.TOAST.ERROR.NB_UNREAD_MESSAGES")))
    }

    const getOrthoptistEarnings = () => {
        axios.get("/admin/statistiques/api/orthoptist/earnings").then(resp => {
            setEarnings(resp.data.data)
        }).catch(() => toast.error(t("ORTHOPTIST_DASHBOARD.TOAST.ERROR.INCOME_DATA")))
    }

    const getAlerts = () => {
        axios.get("/admin/statistiques/api/orthoptist/orthoptist/alerts").then(resp => {
                setAlerts(resp.data.notifications)
            }
        ).catch(() => toast.error(t("ORTHOPTIST_DASHBOARD.TOAST.ERROR.RETRIEVE_ALERTS")))

    }

    const renderUnreadMessages = (message) => {
        return (
            <div id="clickable"
                 onClick={() => window.location.href = "/messages/orthoptiste/conversations/" + message.id}
                 className="card z-depth-0" style={{borderRadius: "10px"}}>
                <h6 className="left-align">{`Consultation de ${Helper.toCamelCase(message.patient_firstname)} ${message.patient_lastname.toUpperCase()} (${message.date})`}</h6>
                <h6 className="left-align grey-text text-darken-2 truncate"
                    style={{fontStyle: "italic"}}>{message.message}</h6>
            </div>
        )
    }

    const renderAlert = (alert) => {
        return (
            <div
                id="clickable"
                onClick={() => window.location.href = `patients/${alert.patient_id}`}
                className={`card z-depth-0 ${alert.color} lighten-5 ${alert.color}-text text-darken-3`}
                style={{borderRadius: "10px", borderColor: `solid thick white`}}>
                <h5 className="center-align truncate" style={{fontWeight: "bold", margin: 5}}>
                    {`${Helper.toCamelCase(alert.patient_firstname)} ${alert.patient_lastname.toUpperCase()}`}
                </h5>
            </div>
        )
    }

    if (alerts === undefined || unreadMessages === undefined || dailyAppointments === undefined || earnings.datasets === undefined) {
        return <div id="full-height"
                    style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Loader wide/>
        </div>
    }
    return (
        <div id="full-height" style={{padding: "6vh 0"}}>
            <div className="row">
                <div className="col xl4 s12" style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: "90%"}}>
                        <MonoStatDisplay
                            link="/agenda/index"
                            label={t("ORTHOPTIST_DASHBOARD.EXAMINATIONS.EXAMINATIONS")}
                            value={dailyAppointments}
                            color="blue"
                        />
                        <div className="blue lighten-5"
                             style={{
                                 height: "60vh", margin: "8px auto",
                                 borderRadius: 5, display: "flex", flexDirection: "column"
                             }}>
                            <div style={{height: "100%", padding: "10px 20px"}}>
                                <BarChart legend={t("ORTHOPTIST_DASHBOARD.EXAMINATIONS.SALES_OVER")} withLegend={false}
                                          data={earnings}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col xl4 s12" style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: "90%"}}>
                        <MonoStatDisplay
                            link="/messages/orthoptiste/conversations"
                            label={t("ORTHOPTIST_DASHBOARD.MESSAGES.MESSAGES")}
                            value={unreadMessages.length}
                            color="green"
                            logo="message"
                        />
                        <div className="green lighten-4 center"
                             style={{
                                 height: "60vh", margin: "8px auto",
                                 borderRadius: 5, display: "flex", flexDirection: "column",
                                 alignItems: "center", overflowY: "scroll", padding: "10px 0", gap: 10
                             }}>
                            {unreadMessages.concat(unreadMessages).map((message, index) =>
                                <div style={{width: "80%"}} key={index}>
                                    {renderUnreadMessages(message)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col xl4 s12" style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: "90%"}}>
                        <MonoStatDisplay
                            link="/notifications/index"
                            label={t("ORTHOPTIST_DASHBOARD.ALERTS.ALERTS")}
                            value={alerts.length}
                            color="red"
                            logo="info"
                        />
                        <div className="red lighten-4 hoverable-scroll-box"
                             style={{
                                 height: "60vh", margin: "8px auto",
                                 borderRadius: 5, display: "flex", flexDirection: "column",
                                 alignItems: "center", padding: "10px 0",
                                 gap: 10
                             }}>
                            {alerts.map((alert, index) =>
                                <div style={{width: "80%"}} key={index}>
                                    <Tooltip id={`alert-tooltip-${index}`} text={alert.gravity}>
                                        {renderAlert(alert)}
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Dashboard