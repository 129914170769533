import React from "react";

const DocumentConclusion = ({ conclusion, appointment, message }) => {
    return (
        <>
            <h5 style={{ marginBlock: 0 }}>
                <b>Conclusion : </b>
            </h5>
            {appointment.status_controle >= 2 ? (
                <h5 style={{ marginBottom: 20, marginTop: 3 }}>
                    {conclusion.conclusion_text ? conclusion.conclusion_text : (message[0]?.message ?? "À la lecture du dossier, l'examen est rassurant.")}
                </h5>
            ) : (
                <h5 style={{ marginBottom: 20, marginTop: 3 }}>
                    L'examen n'a pas encore été contrôlé par un ophtalmologiste.
                </h5>
            )}
        </>
    );
};

export default DocumentConclusion
