import React from 'react';
import BrandedElement from "../../../../Shared/BrandedElement";

const Comments = ({appointment}) => {
    const renderAnamnesis = () => {
        if (!appointment.comment) return <h6><b>Anamnèse :</b> aucune</h6>;
        return <div>
            <h6 style={{fontWeight: "bolder"}}>Anamnèse :</h6>
            <h6>{appointment.comment}</h6>
        </div>
    }

    const renderPrivateComment = () => {
        if (!appointment.private_comment) return <h6><b>Commentaire privé :</b> aucun</h6>;
        return <div>
            <h6 style={{fontWeight: "bolder"}}>Commentaire privé :</h6>
            <h6>{appointment.private_comment}</h6>
        </div>
    }
    const renderOrthopticText = () => {
        if (!appointment.orthoptic_text) return <h6><b>Orthoptie :</b> aucune</h6>;
        return <BrandedElement temeoo>
            <div>
                <h6 style={{fontWeight: "bolder"}}>Orthoptie :</h6>
                <h6>{appointment.orthoptic_text}</h6>
            </div>
        </BrandedElement>
    }

    return <div style={{display: "flex", flexDirection: "column", fontSize: 14}}>
        {renderAnamnesis()}
        {renderPrivateComment()}
        {renderOrthopticText()}
    </div>
}

export default Comments;