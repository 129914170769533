import React, {useEffect, useState} from "react"
import axios from "axios";
import SearchBar from "../../../Messenger/SearchBar";
import OfficeDocuments from "./OfficeDocuments";
import {ArsDocumentsType} from "../../../Shared/Enums/ArsDocumentsType";
import Loader from "../../Controle/Loader";
import {generatePdfIframe} from "../../../Shared/PDF/PDFManager";
import {toast} from "react-toastify";
import Helper from "../../../Shared/Helper";

const sortOffices = (offices) => {
    return offices.sort((office_a, office_b) => {
        let office_a_full_signed = Object.values(office_a.orthoptists).every((orthoptist) => {
            return orthoptist.documents.every((document) => {
                return document.user_signatures.every((signature) => signature.date !== null)
            })
        })
        let office_b_full_signed = Object.values(office_b.orthoptists).every((orthoptist) => {
            return orthoptist.documents.every((document) => {
                return document.user_signatures.every((signature) => signature.date !== null)
            })
        })

        if (office_a_full_signed === office_b_full_signed) return 0
        if (office_a_full_signed && !office_b_full_signed) return 1
        return -1
    })
}

export default function OphtalmologistPanel(props) {

    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content

    const [searchTerm, setSearchTerm] = useState("")
    const [offices, setOffices] = useState(sortOffices(props.data.documents.offices))
    const [collapsible_instances, setCollapsibleInstances] = useState([])
    const [signature_location, setSignatureLocation] = useState(props.data.city ?? "")
    const [documents, setDocuments] = useState(props.data.documents.honor_documents.map((document) => {
        document.iframe = null
        return document
    }))

    useEffect(() => {
        if(!signature_location){
            documents.map((document) => {
                if  (document.user_signature.date && document.user_signature.location) {
                    setSignatureLocation(document.user_signature.location)
                }
            })
        }

        let elements = document.querySelectorAll("[id=honorAttestationCollapsible]")
        setCollapsibleInstances(M.Collapsible.init(elements, {}))
        return () => collapsible_instances.map((instance) => instance.destroy())
    }, [])

    const signDocument = (document_id) => {
        axios.patch(`/signDocument/${document_id}`, {location: signature_location})
            .then((_) => {
                let temp_documents = [...documents]
                let modified_document
                temp_documents.map((document) => {
                    if (document.id === document_id) {
                        document.user_signature.date = new Date()
                        document.user_signature.location = signature_location
                        modified_document = document
                    }
                    handlePdf(modified_document, true)
                    return document
                })
                toast.success("Signature enregistrée.")
            })
            .catch((_) => toast.error("Erreur lors de la signature"))
    }

    const filter = (offices) => {
        if (!searchTerm) return offices

        return offices.reduce((acc, office) => {
            let name_present = office.orthoptists.some((orthoptist) => {
                const fullname = Helper.formatName(orthoptist.first_name, orthoptist.last_name).toLowerCase()
                const fullname_reversed = Helper.formatName(orthoptist.last_name, orthoptist.first_name).toLowerCase()
                const search_term = searchTerm.toLowerCase()
                return fullname.includes(search_term) || fullname_reversed.includes(search_term) ||
                    fullname.replace(/ /g, "").includes(search_term) ||
                    fullname_reversed.replace(/ /g, "").includes(search_term)
            })
            let title_present
            if (office.name){
               title_present = (office.name.toLowerCase().includes(searchTerm.toLowerCase()))
            }
            if (title_present) {
                acc.push(office)
                return acc
            }

            if (name_present) {
                acc.push({
                    ...office, orthoptists: office.orthoptists.filter((orthoptist) => {
                        const fullname = Helper.formatName(orthoptist.first_name, orthoptist.last_name).toLowerCase()
                        const fullname_reversed = Helper.formatName(orthoptist.last_name, orthoptist.first_name).toLowerCase()
                        const search_term = searchTerm.toLowerCase()
                        return fullname.includes(search_term) || fullname_reversed.includes(search_term) ||
                            fullname.replace(/ /g, "").includes(search_term) ||
                            fullname_reversed.replace(/ /g, "").includes(search_term)
                    })
                })
                return acc
            }
            return acc
        }, [])

    }

    const handlePdf = async (modified_document, ignore_existing_iframe = false) => {

        if (modified_document.iframe && !ignore_existing_iframe) return

        let signature_string = Helper.toCamelCase(`${props.data.first_name} ${props.data.last_name}`) ?? ""

        let pdf_data = {
            nom_ophtalmologiste1: Helper.formatName(props.data.first_name, props.data.last_name) ?? "",
            ville_ophtalmo: signature_location ?? "",
            date: Helper.formatDate(modified_document.user_signature.date ?? new Date()),
            signature_ophtalmo1: signature_string
        }

        let document_uri
        let document_title
        switch (modified_document.document_type) {
            case ArsDocumentsType.HONOR_ATTESTATION_6_15.document_type:
                document_uri = ArsDocumentsType.HONOR_ATTESTATION_6_15.url
                document_title = ArsDocumentsType.HONOR_ATTESTATION_6_15.title
                break
            case ArsDocumentsType.HONOR_ATTESTATION_16_50.document_type:
                document_uri = ArsDocumentsType.HONOR_ATTESTATION_16_50.url
                document_title = ArsDocumentsType.HONOR_ATTESTATION_16_50.title
                break
            case ArsDocumentsType.HONOR_ATTESTATION_RETINO.document_type:
                document_uri = ArsDocumentsType.HONOR_ATTESTATION_RETINO.url
                document_title = ArsDocumentsType.HONOR_ATTESTATION_RETINO.title
                break
            default:
                break
        }

        await generatePdfIframe(document_uri, document_title, pdf_data)
            .then((iframe_doc) => {
                    let modified_array = documents.map((document) => {
                        if (document.id === modified_document.id) {
                            return {...document, iframe: iframe_doc}
                        }
                        return document
                    })
                    setDocuments(modified_array)
                }
            )
            .catch((error) => console.log(error))
    }

    const isDocumentSigned = (is_signed) => {
        if (is_signed) {
            return <i className="material-icons green-text">check_circle</i>
        }
        return <i className="material-icons red-text">cancel</i>
    }

    const getMissingElements = (ignore_location = false) => {
        let missing_infos = []
        if (!props.data.last_name) missing_infos.push("Votre nom de famille")
        if (!props.data.first_name) missing_infos.push("Votre prénom")
        if (!ignore_location && !signature_location.length) missing_infos.push("Le lieu de signature")
        return missing_infos
    }


    const renderUserMissingInfosCards = () => {
        let missing_data = getMissingElements(true)
        if (missing_data.length) return <div className="card-panel red lighten-3">
            Des informations importantes sont manquantes pour la finition des dossiers ARS:
            {missing_data.map((element) => <div>- {element}<br/></div>)}
            <div style={{display: "flex", justifyContent: "center"}}>
                <a className="btn red lighten-1 z-depth-0 center"
                   href="/ophtalmologistes/administration#/personal_infos">
                    Remplir mes informations.</a>
            </div>
        </div>

        else return null
    }

    const renderIframe = (iframe) => {
        if (iframe) {
            return <div style={{height: "500px", marginTop: 20, marginBottom: 20}}>
                {iframe}
            </div>
        }
        return <div className="center"><Loader wide text="Chargement du document..."/></div>
    }

    const renderOphtalmologistSignatureInfos = (user_signature) => {
        if (user_signature.date) {
            return <div style={{display: "flex", alignItems: "center"}}>
                <i className="material-icons-outlined green-text" style={{marginRight: 10}}>check_circle</i>
                <div>
                    Signé le {Helper.formatDate(user_signature.date)}
                </div>
            </div>
        }
        return <div style={{display: "flex", alignItems: "center"}}>
            <i className="material-icons-outlined red-text" style={{marginRight: 10}}>cancel</i>
            <div>
                En attente de votre signature.
            </div>
        </div>
    }

    const renderSignatureButton = (document) => {
        if (document.user_signature.date) {
            return <a className="btn blue darken-2 z-depth-0 disabled"
                      style={{display: "flex", alignItems: "center", width: "fit-content", alignSelf: "center"}}>
                <i className="material-icons-outlined" style={{marginRight: 5}}>check_circle</i>
                <div>Déjà signé</div>
            </a>
        }

        let missing_data = getMissingElements()
        if (missing_data.length) {
            return <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: 10,
                marginLeft: 20
            }}>
                Des informations sont manquantes avant de permettre la signature:
                {missing_data.map((element, index) => <div key={`missing_info_card_${index}`}>- {element}<br/></div>)}
            </div>
        }
        return <a className="btn blue darken-2 z-depth-0"
                  onClick={() => signDocument(document.id)}
                  style={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                      alignSelf: "center",
                      marginTop: 10
                  }}>
            <i className="material-icons" style={{marginRight: 5}}>edit_note</i>
            <div style={{paddingTop: 5}}>Signer le document</div>
        </a>
    }

    return <div className="container"
                key={`ophtalmologist_ars_documents_${props.data.user_id}`}
                style={{
                    width: "80%",
                    padding: 20,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 10
                }}>
        <h1 className="center" style={{margin: "20px 0 0 0"}}>Mes documents ARS</h1>

        {renderUserMissingInfosCards()}

        <div style={{paddingBottom: 10}}>
            <h3 className="center"
                style={{marginBottom: 15}}>
                Mes attestations sur l'honneur
            </h3>
            <ul id="honorAttestationCollapsible" className="collapsible">
                {documents.map((document) => {
                    return <li key={`${document.id}_${document.document_type}`}>
                        <div className="collapsible-header"
                             onClick={() => handlePdf(document)}>
                            {isDocumentSigned(document.user_signature.date)}
                            {ArsDocumentsType[document.document_type].title}
                        </div>
                        <div className="collapsible-body">
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <div style={{display: "flex", justifyContent: "space-around"}}>
                                    {renderOphtalmologistSignatureInfos(document.user_signature)}
                                </div>
                                {renderIframe(document.iframe)}
                                {!document.user_signature.date ?
                                    <div className="input-field outlined" style={{width: "50%"}}>
                                        <input type="text"
                                               value={signature_location}
                                               onChange={(e) => setSignatureLocation(e.target.value)}
                                        />
                                        <label>Lieu de signature</label>
                                    </div> : null}
                                {renderSignatureButton(document)}
                            </div>
                        </div>
                    </li>
                })}
            </ul>
        </div>

        <SearchBar onChange={setSearchTerm}/>
        {offices.length ?
            filter(offices).map((office) => {
                return <OfficeDocuments
                    office={office}
                    key={office.office_id}
                    ophtalmologist={{
                        first_name: props.data.first_name,
                        last_name: props.data.last_name,
                        city: props.data.city,
                        rpps: props.data.rpps,
                        adeli: props.data.adeli,
                        user_id: props.data.user_id
                    }}/>
            })
            :
            <div className="center" style={{paddingTop: 20}}>
                Aucun orthoptiste ou de cabinet ne correspondent à votre recherche.
            </div>
        }
    </div>
}