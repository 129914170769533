import React, {Component} from "react";
import DisplayBlocks from "../../../Orthoptistes/Reports/Display/DisplayBlocks";
import axios from "axios";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import PdfViewer from "../../../Orthoptistes/Reports/PdfViewer";
import MesureStore from "../MesureStore";

@observer
export default class WriteReport extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = {
            templates: [],
            selectedTemplate: undefined,
            display: "reports",
            hiddenBlocks: undefined
        }
    }

    componentDidMount() {
        var tabs = document.getElementById("write_report_tabs");

        M.Tabs.init(tabs, {});
        this.getTemplates()
    }

    getTemplates() {
        axios.get("/orthoptistes/reports/all", {params: {appointment_id: MesureStore.appointement_id}}).then((resp) => {
            this.setState({templates: resp.data.reports.templates,}, () => this.getSelectedTemplateId())
        }).catch((err) => {
            toast.error("Impossible de charger les comptes rendus")
        })
    }

    getSelectedTemplateId() {
        axios.get("/orthoptistes/reports/selected_template/id", {params: {appointment_id: MesureStore.appointement_id}}).then((resp) => {
            const templateSelector = document.getElementById('write_report_select');
            let selectedTemplate = {}
            if (resp.data.id === null) {
                if (this.state.templates.length > 0) {
                    selectedTemplate = this.state.templates[0]
                    if (templateSelector) templateSelector.value = 0
                }
            } else {
                selectedTemplate = this.state.templates.find((template) => template.id === resp.data.id)
                if (templateSelector) templateSelector.value = this.state.templates.indexOf(selectedTemplate)
            }
            M.FormSelect.init(templateSelector, {});
            this.setState({
                selectedTemplate: selectedTemplate
            }, () => {
                MesureStore.selected_template = selectedTemplate
            })
        })
    }

    setSelectedTemplateId() {
        axios.patch("/orthoptistes/reports/selected_template", {
            appointment_id: MesureStore.appointement_id,
            selected_template_id: MesureStore.selected_template.id
        }).then()
    }

    selectTemplate(event) {
        const id = parseInt(event.target.value)
        MesureStore.selected_template = this.state.templates.find((template) => template.id === id)
        this.setState({
            selectedTemplate: this.state.templates.find((template) => template.id === id)
        }, () => this.setSelectedTemplateId())
    }

    initCheckboxes = () => {
        if (MesureStore.selected_template && MesureStore.selected_template.blocks) {
            MesureStore.selected_template.blocks.map((block, index) => {
                let checkBox = document.getElementById(`checkbox-templateblock-${block.id}`)
                checkBox.checked = !this.state.hiddenBlocks.includes(block.id)
                if (block.type === "GROUP") {
                    block.data.blocks.map((block, index) => {
                        let checkBox = document.getElementById(`checkbox-templateblock-ingroup-${block.id}`)
                        checkBox.checked = !this.state.hiddenBlocks.includes(block.id)
                    })
                }
            })
        }
    }

    getBlocksIdsInGroup(blockId) {
        let blocksIds = []
        let block = MesureStore.selected_template.blocks.find((block) => block.id === blockId)
        if (block?.type === "GROUP") {
            block.data.blocks.map((block) => {
                blocksIds.push(block.id)
                if (block.type === "GROUP") blocksIds = [...blocksIds, ...this.getBlocksIdsInGroup(block.id)]
            })
        }
        return blocksIds
    }


    toggle(blockId, index) {
        // prevent the auto scroll, it's not a good solution though
        window.scrollTo(0, 0)

        const blocksInGroups = this.getBlocksIdsInGroup(blockId)
        let checkBox = document.getElementById(`checkbox-templateblock-${blockId}`)
        if (checkBox.checked) {
            this.setState({hiddenBlocks: this.state.hiddenBlocks.filter((block) => block !== blockId)})
            blocksInGroups.map((id) => {
                document.getElementById(`checkbox-templateblock-ingroup-${id}`).disabled = false
            })
        } else {
            this.setState({hiddenBlocks: [...this.state.hiddenBlocks, blockId]})
            blocksInGroups.map((id) => {
                document.getElementById(`checkbox-templateblock-ingroup-${id}`).disabled = true
            })
        }
    }

    onBlockClick(block) {
        /*
        @matthias, c'est jc qui a commente ca, ca creait un bug insupportable qui scrollait la page a chaque fois qu'on cliquait sur un bloc
        ne comprenant pas trop le code j ai juste desactive
    
        let blockId = `checkbox-templateblock-label-${block.id}`
        let blockElement = document.getElementById(blockId)
        blockElement.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
        blockElement.style.transition = "color 0.5s"
        blockElement.style.color = "darkblue"
        setTimeout(() => {
            blockElement.style.color = ""
        }, 3000)

         */
    }

    renderCheckbox(block, index, ingroup = false) {
        const isDivider = block.type === "DIVIDER"
        return <div key={index}>
            <label id={`checkbox-templateblock-label-${block.id}`} style={{borderRadius: "5px"}}>
                <input
                    id={`checkbox-templateblock-${ingroup ? "ingroup-" : ""}${block.id}`}
                    type="checkbox" className="filled-in"
                    onFocus={(e) => e.preventDefault()} // Add this line
                    onChange={(e) => this.toggle(block.id, index)}/>
                <span
                    className={`${block.type === "DIVIDER" ? "black-text" : ""}`}>{`${isDivider ? "Séparateur" : block.name}`}</span>
            </label>
            {block.type === "GROUP" ?
                <div style={{margin: "0 0 5px 7px", paddingLeft: "7px", borderLeft: "solid lightgray"}}>
                    {block.data.blocks.map((block, index) => <div
                        key={index}>{this.renderCheckbox(block, index, true)}</div>)}
                </div>
                : <div/>}
        </div>
    }

    renderTabs = () => {
        return <ul className="tabs tabs-fixed-width z-depth-0" id="write_report_tabs">
            <li className="tab" onClick={() => this.setState({display: "reports"})}>
                <a className="active" data-cy="TypeCR" href="#">Comptes rendus</a>
            </li>
            <li className="tab" onClick={() => this.setState({display: "pdfs"})}>
                <a data-cy="TypeCR" href="#">CERFA</a>
            </li>
        </ul>
    }

    renderBlockSelectors = () => {
        if (!this.state.templates) return null

        if (this.state.templates.length === 0) return <div className="center-align">
            Vous n'avez pas de comptes rendus disponibles
            <a className="btn z-depth-0 blue darken-2"
               href="/orthoptistes/reports"
               data-cy="CreateCRLink">
                <i className="material-icons left">edit</i>En créer</a>
        </div>

        return <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            padding: 20,
            height: "100%"
        }}>
            Type de rapport
            <div className="valign-wrapper" style={{gap: 10}}>
                <select className="browser-default" value={MesureStore.selected_template?.id}
                        onChange={(event) => this.selectTemplate(event)}>
                    {this.state.templates.map((template, index) =>
                        <option key={index} value={template.id}>{template.name}</option>
                    )}
                </select>
                {/*{MesureStore.selected_template ? `(v${MesureStore.selected_template.version})` : null}*/}
            </div>

            <div style={{flex: "1 1 0", width: "100%", overflowY: "auto"}}>
                {
                    MesureStore.selected_template?.blocks ? (
                        MesureStore.selected_template.blocks.map((block, index) =>
                            (this.renderCheckbox(block, index))
                        )) : (<div/>)
                }
            </div>
        </div>
    }

    renderTemplates = () => {
        if (!this.state.display === "reports") return null
        if (!this.state.selectedTemplate) return null
        return <DisplayBlocks template={MesureStore.selected_template}
                              appointment_id={MesureStore.appointement_id}
                              hiddenBlocks={this.state.hiddenBlocks}
                              onBlockClick={(block) => this.onBlockClick(block)}
                              setHiddenBlocks={(hiddenBlocks) => {
                                  this.setState({hiddenBlocks: hiddenBlocks}, () => this.initCheckboxes())
                              }}
                              appointment_pending
        />
    }

    renderPdfViewer = () => {
        if (!this.state.display === "pdfs") return null
        return <div style={{padding: 10}}>
            <PdfViewer/>
        </div>
    }

    renderTab = () => {
        switch (this.state.display) {
            case "reports":
                return <div className="row" style={{marginBlock: 10}}>
                    <div className="col s2">
                        {this.renderBlockSelectors()}
                    </div>
                    <div className="col s10" style={{
                        justifyContent: "center",
                        display: "flex"
                    }}>
                        {this.renderTemplates()}
                    </div>
                </div>
            case "pdfs":
                return this.renderPdfViewer()
            default:
                return null
        }
    }

    render() {
        return <div style={{
            boxShadow: "0px -2px 0px 1px rgb(238, 238, 238)",
            display: "flex",
            flexDirection: "column",
            height: "100%"
        }}>
            {this.renderTabs()}
            <div style={{flex: "1 1 0", overflowY: "scroll"}}>
                {this.renderTab()}
            </div>
        </div>
    }
}