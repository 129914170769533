import {PDFDocument} from "pdf-lib";
import JSZip from 'jszip';
import React from "react"
import {saveAs} from 'file-saver';
import html2pdf from "html-to-pdf-js";
import fontkit from '@pdf-lib/fontkit'

const fillPdf = async (pdfURI, formData) => {
    const formPdfBytes = await fetch(pdfURI).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    const form = pdfDoc.getForm()

    Object.entries(formData).map(([key, value]) => {
        if (value === null || value === undefined) return

        if (value instanceof String || typeof value === "string") form.getTextField(key).setText(value)
        else if ((value instanceof Boolean || typeof value === "boolean") && value) form.getCheckBox(key).check()
        else form.getTextField(key).setText(value.toString())
    })
    return await pdfDoc.save()
}

const generatePdf = async (pdfURI, formData) => {
    const pdfBytes = await fillPdf(pdfURI, formData);
    const url = URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf'}));

    let newTab = window.open('_blank');
    newTab.document.write(`<iframe src="${url}" style="width:100%; height:100%;" name="coucou"></iframe>`);
}

const fillAndLockPdf = async (pdfURI, pdf_name, formData) => {
    const formPdfBytes = await fetch(pdfURI).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    pdfDoc.registerFontkit(fontkit)

    const signature_font_bytes = await fetch("/Brush Script MT Italic.ttf").then(res => res.arrayBuffer())
    const signature_font = await pdfDoc.embedFont(signature_font_bytes)
    pdfDoc.setTitle(pdf_name)
    const form = pdfDoc.getForm()

    const fields = form.getFields()
    fields.map((field) => {
        let field_name = field.getName()
        for (let property in formData) {
            if (field_name === property) {
                form.getTextField(field_name).setText(formData[property])
                if (field_name.includes("signature")) form.getTextField(field_name).updateAppearances(signature_font)
                break
            }
        }
        field.enableReadOnly()
    })
    return await pdfDoc.save()
}

export const generatePdfIframe = async (pdfURI, pdf_name, formData,) => {
    const pdfBytes = await fillAndLockPdf(pdfURI, pdf_name, formData);
    const url = URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf'}));
    return <iframe src={url} style={{width: "100%", height: "100%"}} name="coucou"></iframe>
}

const addImage = async (pdfDoc, imageURI, position) => {
    const imageBytes = fs.readFileSync(imageURI);
    const image = await pdfDoc.embedJpg(imageBytes);
}


export const generatePDFArchive = async (pdfURI, formDatas) => {
    const blobs = await Promise.all(formDatas.map(async (formData, index) => {
        const pdfBytes = await fillPdf(pdfURI, formData);
        return new Blob([pdfBytes], {type: 'application/pdf'})
    }));

    await downloadPDFsAsZip(blobs)
}

async function downloadPDFsAsZip(pdfs) {
    // Create a new JSZip instance
    const zip = new JSZip();

    // Add each PDF blob to the zip file
    pdfs.forEach((pdfBlob, index) => {
        zip.file(`document_${index + 1}.pdf`, pdfBlob);
    });

    // Generate the zip file as a blob
    const zipBlob = await zip.generateAsync({type: 'blob'});

    // Trigger the download using the FileSaver library
    saveAs(zipBlob, 'pdfs.zip');
}

export const generatePdfFromUrl = (url) => {
    return new Promise(async (resolve, reject) => {
        const iframe = document.createElement('iframe');
        iframe.src = url
        iframe.style.visibility = 'hidden';
        iframe.style.position = 'absolute';
        iframe.style.left = '-9999px';
        iframe.style.top = '-9999px';
        //iframe must be A4
        iframe.style.width = '210mm';
        iframe.style.height = '297mm';
        //add data is loaded to 0
        document.body.appendChild(iframe);

        let loaded = false;
        iframe.onload = () => loaded = true;

        await new Promise((resolve) => {
            const int = setInterval(() => {
                if (iframe.contentWindow.status === "ready" && loaded) {
                    clearInterval(interval);
                    resolve();
                }
            }, 200);
            setTimeout(() => {
                clearInterval(int);
                resolve();
            }, 10000);
        });


        // start busy wait until iframe.contentWindow.status == "ready" and iframe loaded
        let worker = html2pdf()
        const html = iframe.contentWindow.document.querySelector(".pdf-document")

        const contentHeight = html.offsetHeight;
        const pdfPageHeight = 1040;
        const nbPages = Math.ceil(contentHeight / pdfPageHeight);
        html.style.height = nbPages * pdfPageHeight;

        // const hasLowMemory = navigator.deviceMemory && navigator.deviceMemory < 2; // e.g., less than 2GB RAM
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        const imgQuality = isMobile ? 0.5 : 1;
        const scale = isMobile ? 2 : 4;

        await worker.from(html)
            .set({
                margin: [10, 0],
                pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
                // filename: `${doc.title}_${Helper.formatDate(metadata?.appointment?.start)}`,
                filename: "test",
                html2canvas: {scale: scale},
                jsPDF: {unit: 'mm', format: 'a4', orientation: 'p'},
                image: {type: 'jpeg', quality: imgQuality}
            }).to('pdf').output('blob').then((blob) => {
                resolve(blob)
            })
    })
}


export default generatePdf;
// export default {generatePdf, generatePDFArchive};